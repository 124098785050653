import React, { FC, ReactNode, ReactElement, createContext, useState, useMemo, useEffect } from "react";
import { useGetFoldersV2Query } from "utils/redux/Optimization/folderapiSlice";
import { Folder } from "types/folder";

// Set Props type.
type Props = { children: ReactNode };

interface FolderMarketData {
  market: string;
  id: number;
}

export type FolderType = {
  folderData: Folder[];
  setFolderData: React.Dispatch<React.SetStateAction<Folder[]>>;
  selectedfolderId: number;
  setSelectedFolderId: React.Dispatch<React.SetStateAction<number>>;
  folderId: number;
  setFolderId: React.Dispatch<React.SetStateAction<number>>;
  folderName: string;
  setFolderName: React.Dispatch<React.SetStateAction<string>>;
  folderManualScenarioName: any;
  setFolderManualScenarioName: React.Dispatch<React.SetStateAction<any>>;
  defaultId: any;
  setDefaultId: React.Dispatch<React.SetStateAction<any>>;
  refId: number;
  setRefId: React.Dispatch<React.SetStateAction<number>>;
  folderMenu: Folder;
  setFolderMenu: React.Dispatch<React.SetStateAction<Folder>>;
  folderModifiedDate: string;
  setFolderModifiedDate: React.Dispatch<React.SetStateAction<string>>;
  folderMarketData: FolderMarketData;
  setFolderMarketData: React.Dispatch<React.SetStateAction<FolderMarketData>>;
};

export const FolderDisplayContext = createContext<FolderType>({
  folderData: [],
  setFolderData: () => null,
  selectedfolderId: null,
  setSelectedFolderId: () => null,
  folderId: null,
  setFolderId: () => null,
  folderName: "",
  setFolderName: () => null,
  folderManualScenarioName: null,
  setFolderManualScenarioName: () => null,
  defaultId: null,
  setDefaultId: () => null,
  refId: null,
  setRefId: () => null,
  folderMenu: null,
  setFolderMenu: () => null,
  folderModifiedDate: "",
  setFolderModifiedDate: () => null,
  folderMarketData: { market: "", id: 0 },
  setFolderMarketData: () => null,
});

const FolderDisplayContextProvider: FC<Props> = ({ children }): ReactElement => {
  const { data } = useGetFoldersV2Query();
  const [folderId, setFolderId] = useState<number>();
  const [folderData, setFolderData] = useState([]);
  const [selectedfolderId, setSelectedFolderId] = useState<number>();
  const [folderName, setFolderName] = useState<string>("");
  const [folderManualScenarioName, setFolderManualScenarioName] = useState();
  const [defaultId, setDefaultId] = useState();
  const [refId, setRefId] = useState<number>();
  const [folderMenu, setFolderMenu] = useState<Folder>();
  // const { data: referenceScenarioData } = useGetFoldersbyIdQuery(folderId);
  const [folderModifiedDate, setFolderModifiedDate] = useState<string>("");
  const [folderMarketData, setFolderMarketData] = useState<FolderMarketData>({ market: "", id: 0 });

  useEffect(() => {
    setFolderData(data?.activeFoldersList);
  }, [data]);

  useEffect(() => {
    if (folderData && folderData.length > 0) {
      setDefaultId(folderData?.find((folder) => folder.name === "Default Folder")?.folderId);
    }
  }, [folderData]);

  useEffect(() => {
    const persistedFolderData = JSON.parse(localStorage.getItem("folder"));
    if (persistedFolderData && persistedFolderData.marketId) {
      const folderMarket = { market: persistedFolderData.market, id: persistedFolderData.marketId };
      setFolderMarketData(folderMarket);
      setFolderId(persistedFolderData.folderId);
      setFolderName(persistedFolderData.folderName);
      setFolderModifiedDate(persistedFolderData.folerModifiedDate);
      setRefId(persistedFolderData.refId);
    }
  }, []);

  const value = useMemo(
    () => ({
      folderData,
      setFolderData,
      selectedfolderId,
      setSelectedFolderId,
      folderId,
      setFolderId,
      folderName,
      setFolderName,
      folderManualScenarioName,
      setFolderManualScenarioName,
      defaultId,
      setDefaultId,
      refId,
      setRefId,
      folderMenu,
      setFolderMenu,
      folderModifiedDate,
      setFolderModifiedDate,
      folderMarketData,
      setFolderMarketData,
    }),
    [
      folderData,
      selectedfolderId,
      folderId,
      folderName,
      folderManualScenarioName,
      defaultId,
      refId,
      folderMenu,
      folderModifiedDate,
      folderMarketData,
    ],
  );
  return <FolderDisplayContext.Provider value={value}>{children}</FolderDisplayContext.Provider>;
};

export default FolderDisplayContextProvider;
