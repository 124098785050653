import React from "react";
import { OktaProvider } from "okta/OktaProvider";
import { OktaClient } from "okta/OktaClient";
import "./styles/app.scss";

const App = () => {
  return (
    <OktaProvider>
      <OktaClient />
    </OktaProvider>
  );
};

export default App;
