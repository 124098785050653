import { flusLabels, pmfLabels } from "constants/textLabels";
import React, {
  Dispatch,
  FC,
  ReactNode,
  ReactElement,
  createContext,
  SetStateAction,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { IAddScenario } from "types/addScenario";
import { TextLabels } from "types/textLabels";
import { FolderDisplayContext } from "./FolderDisplayContext";

// Set Props type.
type Props = { children: ReactNode };

export type minMaxErrorIndexesType = Record<string, Record<number, Record<"min" | "max", string>>>;

export type AddScenarioType = {
  newScenario: IAddScenario;
  minMaxErrorIndexes: minMaxErrorIndexesType;
  reset: boolean;
  setReset: Dispatch<SetStateAction<boolean>>;
  setMinMaxErrorIndexes: Dispatch<SetStateAction<minMaxErrorIndexesType>>;
  setNewScenario: Dispatch<SetStateAction<IAddScenario>>;
  sortSubCatsByBuSize: (newScenario: IAddScenario, cat: string, sortType: string) => void;
  isMarketPMF?: boolean;
  scenarioLabels?: TextLabels;
};

export const AddScenarioContext = createContext<AddScenarioType>({
  newScenario: null,
  minMaxErrorIndexes: null,
  reset: false,
  setReset: () => null,
  setMinMaxErrorIndexes: () => null,
  setNewScenario: () => null,
  sortSubCatsByBuSize: () => null,
  isMarketPMF: null,
  scenarioLabels: null,
});

const MARKET_PMF = "PMF";

const AddScenarioContextProvider: FC<Props> = ({ children }): ReactElement => {
  const folderContext = useContext(FolderDisplayContext);
  const [newScenario, setNewScenario] = useState<IAddScenario>();
  const [minMaxErrorIndexes, setMinMaxErrorIndexes] = useState<minMaxErrorIndexesType>({});
  const [reset, setReset] = useState<boolean>(false);
  const [isMarketPMF, setIsMarketPMF] = useState<boolean>(folderContext?.folderMarketData?.market === MARKET_PMF);
  const [scenarioLabels, setScenarioLabels] = useState<TextLabels>(isMarketPMF ? pmfLabels : flusLabels);

  const sortSubCatsByBuSize = useCallback((newScenario: IAddScenario, cat: string, sortType: string) => {
    newScenario.scenarioCat[cat].subCats.sort((a, b) => {
      const aBuSize = a.buSize.toLowerCase();
      const bBuSize = b.buSize.toLowerCase();

      if (sortType === "DESC") {
        if (aBuSize < bBuSize) {
          return 1;
        }
        if (aBuSize > bBuSize) {
          return -1;
        }
        return 0;
      } else {
        if (aBuSize < bBuSize) {
          return -1;
        }
        if (aBuSize > bBuSize) {
          return 1;
        }
        return 0;
      }
    });
  }, []);

  useEffect(() => {
    const isPMF = folderContext?.folderMarketData?.market === MARKET_PMF;
    setIsMarketPMF(isPMF);
    setScenarioLabels(isPMF ? pmfLabels : flusLabels);
  }, [folderContext?.folderMarketData?.market]);

  useEffect(() => {
    //sort scenario subcategories by buSize
    if (newScenario?.scenarioCat && JSON.stringify(newScenario?.scenarioCat) !== "{}") {
      for (const cat in newScenario.scenarioCat) {
        sortSubCatsByBuSize(newScenario, cat, "ASC");
      }
    }

    // setNewScenario(scenario);
  }, [newScenario, sortSubCatsByBuSize]);

  const value = useMemo(
    () => ({
      newScenario,
      setNewScenario,
      minMaxErrorIndexes,
      setMinMaxErrorIndexes,
      sortSubCatsByBuSize,
      reset,
      setReset,
      isMarketPMF,
      scenarioLabels,
    }),
    [newScenario, isMarketPMF],
  );

  return <AddScenarioContext.Provider value={value}>{children}</AddScenarioContext.Provider>;
};

export default AddScenarioContextProvider;
