// local dependencies.
import { maintenanceResponse } from "types/result";
import { emptySplitApi } from "../api/emptySplitApi";
import { MAINTENANCE_MODE_API_URL } from "constants/apiEndpoints";

// Define service slice and endpoints.
const maintenanceapislice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMaintenance: builder.query<maintenanceResponse, void>({
      query: () => MAINTENANCE_MODE_API_URL,
      providesTags: [],
    }),
  }),
  overrideExisting: false,
});

// Define hooks.
const { useGetMaintenanceQuery } = maintenanceapislice;

// Export slice and hooks.
export { maintenanceapislice, useGetMaintenanceQuery };
