import React, { createContext, useState, useMemo, Dispatch, SetStateAction, ReactNode, ReactElement, FC } from "react";
import { Scenario } from "types/scenario";

type Props = { children: ReactNode };

type ScenarioType = {
  scenario: Scenario;
  setScenario: Dispatch<SetStateAction<Scenario>>;
  checkedBuSize: any;
  setCheckedBuSize: Dispatch<SetStateAction<any>>;
};

export const CreateScenarioDisplayContext = createContext<ScenarioType>({
  scenario: null,
  setScenario: () => null,
  checkedBuSize: null,
  setCheckedBuSize: () => null,
});

const CreateScenarioDisplayContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [scenario, setScenario] = useState<Scenario>({
    name: "Default",
    maximizationGoal: "cpg_profit",
    netSales: 100,
    grossMargin: 100,
    volume: 100,
    inputFinancialConstraints: [
      { subCategory: "Potato Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0 },
      { subCategory: "Unflavored Tortilla Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0 },
      { subCategory: "Flavored Tortilla Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0 },
      { subCategory: "Tortilla Chips", minNetSales: 0, minGrossMargin: 0, minVolume: 0 },
      { subCategory: "Multi Packs", minNetSales: 0, minGrossMargin: 0, minVolume: 0 },
    ],
    scenarioType: "optimized",
    inputPricingConstraints: {
      minPriceChange: 0,
      maxPriceChange: 12,
      stepPriceChange: 4,
      linePricingBrandSize: false,
      incentivesCurvesMinGap: 10,
    },
  });
  const [checkedBuSize, setCheckedBuSize] = useState([]);

  const value = useMemo(() => ({ scenario, setScenario, checkedBuSize, setCheckedBuSize }), [scenario, checkedBuSize]);

  return <CreateScenarioDisplayContext.Provider value={value}>{children}</CreateScenarioDisplayContext.Provider>;
};

export default CreateScenarioDisplayContextProvider;
