//Local dependencies.
import { Folder, FoldersV2 } from "types/folder";
import { emptySplitApi } from "../api/emptySplitApi";
import { FOLDERS_API_URL } from "constants/apiEndpoints";

// Define service slice and endpoints.
const folderapiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFolders: builder.query<Folder[], void>({
      query: () => FOLDERS_API_URL,
      transformResponse: (response: Folder[]) => response.sort((a, b) => a.folderId - b.folderId),
      providesTags: ["Folders"],
      keepUnusedDataFor: 1,
    }),
    getFoldersbyId: builder.query<Folder, number>({
      query: (id: number) => `${FOLDERS_API_URL}/${id}`,
      providesTags: (result, error, id) => [{ type: "Folders", id }],
    }),
    addFolders: builder.mutation<Folder, Partial<Folder>>({
      query: (folder: Folder) => ({ url: FOLDERS_API_URL, method: "POST", body: folder }),
      invalidatesTags: ["Folders"],
    }),
    updateFolders: builder.mutation<Folder, Partial<Folder>>({
      query: (folder: Folder) => ({ url: `${FOLDERS_API_URL}/${folder.folderId}`, method: "PATCH", body: folder }),
      invalidatesTags: ["Folders"],
    }),
    deleteFolders: builder.mutation<{ success: boolean; id: number }, Partial<Folder>>({
      query: (folder: Folder) => ({ url: `${FOLDERS_API_URL}/${folder.folderId}`, method: "DELETE", body: folder }),
      invalidatesTags: ["Folders"],
    }),
    archiveFolder: builder.mutation<Folder, Partial<Folder>>({
      query: (folder: Folder) => ({ url: `${FOLDERS_API_URL}/archive`, method: "POST", body: folder }),
      invalidatesTags: ["Folders"],
    }),
    getFoldersV2: builder.query<FoldersV2, void>({
      query: () => `${FOLDERS_API_URL}/v2`,
      providesTags: ["Folders"],
    }),
    getArchivedFolders: builder.query<Folder[], void>({
      query: () => ({ url: `${FOLDERS_API_URL}/archiveFoldersData`, method: "GET" }),
      transformResponse: (response: Folder[]) => response.sort((a, b) => a.folderId - b.folderId),
      providesTags: ["Folders"],
      keepUnusedDataFor: 1,
    }),
  }),
  overrideExisting: false,
});

// Define hooks.
const {
  useGetFoldersQuery,
  useGetFoldersbyIdQuery,
  useAddFoldersMutation,
  useUpdateFoldersMutation,
  useDeleteFoldersMutation,
  useArchiveFolderMutation,
  useGetFoldersV2Query,
  useGetArchivedFoldersQuery,
} = folderapiSlice;

// Export slice and hooks.
export {
  folderapiSlice,
  useGetFoldersQuery,
  useGetFoldersbyIdQuery,
  useAddFoldersMutation,
  useUpdateFoldersMutation,
  useDeleteFoldersMutation,
  useArchiveFolderMutation,
  useGetFoldersV2Query,
  useGetArchivedFoldersQuery,
};
