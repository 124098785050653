/**
 * © 2023 Boston Consulting Group
 * BCG X
 * Shon Little
 * Created: 2023-02-02
 */

// Add local dependencies.
import { ResultApi as ResultApi2, ResultApiPayload, ScenarioTypes } from "types/resultV2";
import { emptySplitApi } from "../api/emptySplitApi";
import { OUTPUT_DETAILS_API_URL_V2 } from "constants/apiEndpoints";

// for new/refactored result table
const resultApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getResultsV2: builder.query<{ res: ResultApi2; type: ScenarioTypes }, ResultApiPayload>({
      query: (args: ResultApiPayload) => {
        if (args.scenarioId) {
          let url = `${OUTPUT_DETAILS_API_URL_V2}/${args.scenarioId}`;
          const params = new URLSearchParams();
          if (args.isCompareScenario !== undefined) {
            params.append("isCompareScenario", String(args.isCompareScenario));
          }
          if (args.isAdjustedScenario !== undefined) {
            params.append("isAdjustScenario", String(args.isAdjustedScenario));
          }
          if (params.toString()) {
            url += `?${params.toString()}`;
          }
          return url;
        }
      },
      transformResponse: (response: ResultApi2, meta: unknown, args: ResultApiPayload) => {
        return { type: args.type, res: response };
      },
      providesTags: ["Results"],
    }),
  }),
});

// Define hooks.
const { useGetResultsV2Query } = resultApi;

// Export slice and hooks.
export { resultApi, useGetResultsV2Query };
