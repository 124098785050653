// Third-party dependencies.
import React, { FC, ReactElement, MouseEvent, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Logout from "@mui/icons-material/Logout";

// Local dependencies.
import { mainDropdownMenuStyle } from "./mainDropdownMenuStyle";
import { signOut } from "utils/okta";

export interface IMainDropdownMenu {
  userNameInitial: string;
}

interface IMenuItem {
  icon: JSX.Element;
  name: string;
  type: string;
}

const menuItems: IMenuItem[] = [
  {
    icon: <Logout data-testid="logoutIcon" fontSize="small" />,
    name: "Logout",
    type: "logout",
  },
];

const MainDropdownMenu: FC<IMainDropdownMenu> = (props): ReactElement => {
  const logout = async () => signOut();

  const { userNameInitial } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserNavClick = (itemType: string) => {
    switch (itemType) {
      case "logout":
        logout();
        break;
      default:
        console.log("No navigation path for selected option");
    }
  };

  return (
    <>
      <Box onClick={handleClick} sx={mainDropdownMenuStyle.wrapper}>
        <IconButton
          size="small"
          sx={mainDropdownMenuStyle.iconButton}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={mainDropdownMenuStyle.avatar} data-testid="avatar">
            {userNameInitial}
          </Avatar>
          <ExpandMoreIcon
            sx={open ? { transform: "rotate(180deg)", color: "var(--white-1)" } : { color: "var(--white-1)" }}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            ...mainDropdownMenuStyle.menu,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menuItems.map((item: IMenuItem) => {
          if (item.name === "Logout") {
            return (
              <span key={`${item.name}-main-menu`}>
                <MenuItem onClick={() => handleUserNavClick(item.type)} sx={mainDropdownMenuStyle.menuItem}>
                  <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>{item.icon}</ListItemIcon>
                  {item.name}
                </MenuItem>
              </span>
            );
          }

          return (
            <MenuItem
              key={`${item.name}-main-menu`}
              onClick={() => handleUserNavClick(item.type)}
              sx={mainDropdownMenuStyle.menuItem}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MainDropdownMenu;
