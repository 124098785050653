/**
 * © 2023 Boston Consulting Group
 * BCG X
 * Shon Little
 * Created: 2023-02-06
 */

// Add third-party dependencies.
import React from "react";

const CombinedContextComponents = (...components) => {
  let components_reverse = components.reverse();
  return  components_reverse.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      // eslint-disable-next-line react/display-name
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};

// Export component.
export default CombinedContextComponents;
