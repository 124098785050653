// Add third-party dependencies.
import React, { FC, ReactNode, ReactElement, createContext, useState, useMemo, useEffect } from "react";

// Add type dependencies.
import type { Scenario } from "types/scenario";

// Set Props type.
type Props = { children: ReactNode };
export type ScenarioType = {
  scenarioMenu: Scenario;
  setScenarioMenu: React.Dispatch<React.SetStateAction<Scenario>>;
  scenarioGlobalId: number;
  setScenarioGlobalId: React.Dispatch<React.SetStateAction<number>>;
  scenarioDate: Date;
  setScenarioDate: React.Dispatch<React.SetStateAction<Date>>;
  scenarioName: any;
  setScenarioName: React.Dispatch<React.SetStateAction<any>>;
  compareId: any[];
  setCompareId: React.Dispatch<React.SetStateAction<any[]>>;
  compareName: any[];
  setCompareName: React.Dispatch<React.SetStateAction<any[]>>;
  scenarioStatus: any;
  setScenarioStatus: React.Dispatch<React.SetStateAction<any>>;
  scenarioList: Scenario[];
  setScenarioList: React.Dispatch<React.SetStateAction<Scenario[]>>;
};

/**
 * Scenario Display Context.
 */
export const ScenarioDisplayContext = createContext<ScenarioType>({
  scenarioMenu: null,
  setScenarioMenu: () => null,
  scenarioGlobalId: null,
  setScenarioGlobalId: () => null,
  scenarioDate: null,
  setScenarioDate: () => null,
  scenarioName: null,
  setScenarioName: () => null,
  compareId: [],
  setCompareId: () => null,
  compareName: [],
  setCompareName: () => null,
  scenarioStatus: null,
  setScenarioStatus: () => null,
  scenarioList: [],
  setScenarioList: () => null,
});

/**
 * Scenario Display Context Provider.
 * @param param0 {Object} - Child components.
 * @returns {FC} - Functional component
 */
const ScenarioDisplayContextProvider: FC<Props> = ({ children }): ReactElement => {
  // Set state hooks.
  const [scenarioMenu, setScenarioMenu] = useState<Scenario>();
  const [scenarioGlobalId, setScenarioGlobalId] = useState<number>();
  const [scenarioDate, setScenarioDate] = useState<Date>();
  const [scenarioStatus, setScenarioStatus] = useState();
  const [scenarioName, setScenarioName] = useState();
  const [compareId, setCompareId] = useState([]);
  const [compareName, setCompareName] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);

  // Persist global scenario ID to local storage.
  useEffect(() => {
    if (scenarioGlobalId) localStorage.setItem("persistedScenarioId", scenarioGlobalId.toString());
  }, [scenarioGlobalId]);

  // Retrieve global scenario ID from local storage if persisted.
  useEffect(() => {
    const persistedScenarioId = parseInt(localStorage.getItem("persistedScenarioId"), 10);
    if (persistedScenarioId) setScenarioGlobalId(persistedScenarioId);
  }, []);

  // Set memo hook.
  const value = useMemo(
    () => ({
      scenarioMenu,
      setScenarioMenu,
      scenarioDate,
      setScenarioDate,
      scenarioName,
      setScenarioName,
      compareId,
      setCompareId,
      compareName,
      setCompareName,
      scenarioGlobalId,
      setScenarioGlobalId,
      scenarioStatus,
      setScenarioStatus,
      scenarioList,
      setScenarioList,
    }),
    [scenarioMenu, scenarioDate, scenarioName, compareId, compareName, scenarioGlobalId, scenarioStatus, scenarioList],
  );

  // Return context provider.
  return <ScenarioDisplayContext.Provider value={value}>{children}</ScenarioDisplayContext.Provider>;
};

// Export context provider.
export default ScenarioDisplayContextProvider;
