// local dependencies.
import { AUTHORIZE_USER_API_URL } from "constants/apiEndpoints";
import { IAuthorizeUser } from "types/authorizeUser";
import { emptySplitApi } from "./emptySplitApi";

// Define service slice and endpoints.
const IAuthorizeUserApiSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAuthorizeUser: builder.query<IAuthorizeUser, void>({
        query: () => AUTHORIZE_USER_API_URL,
        providesTags: ["AuthorizeUserDetails"],
      }),
    };
  },
  overrideExisting: false,
});

// Define hooks.
const { useGetAuthorizeUserQuery } = IAuthorizeUserApiSlice;

// Export slice and hooks.
export { IAuthorizeUserApiSlice, useGetAuthorizeUserQuery };
