import React, { lazy, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/navigation/GlobalHeader";
import NavBar from "../components/navigation/NavBar";

import { MAIN_ROUTES } from "constants/routes";
import { AppLayout } from "components/materials/containers/AppLayout";
import MaintenancePage from "modules/MaintenancePage/MaintenancePage";
import { AuthorizeUserContext } from "contexts/AuthorizeUserContext";
import { GROWTHBOOK_CLIENT_ID, GROWTHBOOK_HOST } from "config/config";
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import * as FullStory from "@fullstory/browser";
import { useAppSelector } from "utils/redux/hooks";
import { isSuperUserSelector } from "utils/redux/maintenance/maintenanceSlice";
import { useGetMaintenanceQuery } from "utils/redux/api/maintenanceSplitApi";

// eslint-disable-next-line import/no-extraneous-dependencies
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { IUserTrackData } from "types/authorizeUser";
import ThemeLayout from "./ThemeLayout";
import SuspenseLoader from "components/lazy/SuspenseLoader";
import { DEFAULT_MARKET, PRICING_APP_ID } from "constants/textConstants";
import { useGetFeaturesQuery } from "utils/redux/featureFlags/featureFlagSplitApi";

// Lazy loads
const LazyOptimization = lazy(() => import("./OptimizationsLayout"));
const LazyCreateScenario = lazy(
  () => import("modules/Optimization/modules/ScenarioList/modules/CreateScenario/CreateScenario"),
);
const LazyCompareScenario = lazy(() => import("./CompareScenarioLayout"));
const LazyResults = lazy(() => import("./ResultsLayout"));
const LazyError404 = lazy(() => import("modules/fallbacks/Error404"));
const LazyErrorScreen = lazy(() => import("modules/fallbacks/ErrorScreen/ErrorScreen"));
const LazyNoAccess = lazy(() => import("modules/fallbacks/NoAccessScreen/NoAccess"));
const LazySimulation = lazy(() => import("modules/Simulation/Simulation"));
const LazyHomePage = lazy(() => import("modules/Home/HomePage"));
const LazySupport = lazy(() => import("./SupportLayout"));

const MainLayout = () => {
  const { data, isLoading } = useGetMaintenanceQuery();
  useGetFeaturesQuery();
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const { authorizeUserDetails: user } = useContext(AuthorizeUserContext);
  const superUser = useAppSelector(isSuperUserSelector);
  const [userDataSent, setUserDataSent] = useState<boolean>(false);

  const growthbook = new GrowthBook({
    apiHost: GROWTHBOOK_HOST,
    clientKey: GROWTHBOOK_CLIENT_ID,
    enableDevMode: true,
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
      // TODO: Use your real analytics tracking system
      console.log("Viewed Experiment", {
        experimentId: experiment.key,
        variationId: result.key,
      });
    },
  });

  growthbook.init({
    // Optional, enable streaming updates
    streaming: true,
  });

  const findUserMarket = () => {
    const pricingMarkets = user?.userAccessRoles?.filter((role) => role.appId === PRICING_APP_ID);
    return pricingMarkets.length > 1
      ? "Admin"
      : pricingMarkets[DEFAULT_MARKET]?.marketName ?? pricingMarkets[0]?.marketName;
  };

  useEffect(() => {
    if (!isLoading && data) {
      const isUnderMaintenance = data.enabled && data.apps.includes("PricingAi");
      setMaintenanceMode(isUnderMaintenance);
    }
  }, [isLoading, data]);

  useEffect(() => {
    const userReadyToTrack =
      user?.gpid && user?.email && user?.firstName && user?.lastName && FullStory.isInitialized();

    if (!userDataSent && userReadyToTrack) {
      const userAttributes: IUserTrackData = {
        gpid: user.gpid,
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        userType: user.userType,
        marketName: findUserMarket(),
        userTagId: user.userTagId,
        userTag: user.userTagList[user.userTagId],
      };
      // GrowthBook tracking setup
      growthbook.setAttributes(userAttributes);
      // FullStory tracking setup
      FullStory.identify(user.gpid, userAttributes);
      setUserDataSent(true);
    }
  }, [user]);

  const MainRouter = () => {
    return (
      <SuspenseLoader>
        <Routes>
          <Route path={MAIN_ROUTES.INDEX} element={<LazyOptimization />} />
          {/* CreateScenario is a single page, to avoid problems, we will keep it as is */}
          <Route path={MAIN_ROUTES.SCENARIO}>
            <Route path=":folderId/:scenarioId/:copy" element={<LazyCreateScenario />} />
            <Route path=":folderId/:scenarioId" element={<LazyCreateScenario />} />
            <Route path=":folderId" element={<LazyCreateScenario />} />
          </Route>
          <Route path={MAIN_ROUTES.SCENARIOS} element={<LazyOptimization />} />
          <Route path={MAIN_ROUTES.COMPARISIONS} element={<LazyCompareScenario />} />
          <Route path={MAIN_ROUTES.RESULTS} element={<LazyResults />} />
          <Route path={MAIN_ROUTES.ERROR} element={<LazyError404 />} />
          <Route path={MAIN_ROUTES.ERROR_SCREEN} element={<LazyErrorScreen />} />
          <Route path={MAIN_ROUTES.NO_ACCESS} element={<LazyNoAccess />} />
          <Route element={<ThemeLayout />}>
            <Route path={MAIN_ROUTES.SIMULATION} element={<LazyHomePage />} />
            {user?.userIsPMF ? (
              <Route path={MAIN_ROUTES.INDEX} element={<LazyOptimization />} />
            ) : (
              <Route path={MAIN_ROUTES.PRICING_SIMULATION} element={<LazySimulation />} />
            )}
          </Route>
          <Route path={MAIN_ROUTES.SUPPORT} element={<LazySupport />} />
        </Routes>
      </SuspenseLoader>
    );
  };

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <AppLayout fullWidth disableGutters>
        <Header />
        <NavBar />
        <div className="newLayout">
          {maintenanceMode ? (
            superUser.superUser ? (
              <MainRouter />
            ) : (
              <MaintenancePage data={data} user={user} />
            )
          ) : (
            <MainRouter />
          )}
        </div>
      </AppLayout>
    </GrowthBookProvider>
  );
};

export default MainLayout;
