import { ISubCategoryList, IProduct } from "types/homeFilters";
import { PepPeridod } from "types/pricingSimulationFilter";

export const getBrandsAndSizes = (filtersDataSubCategoryList: ISubCategoryList[], selectedSubCategories: string[]) => {
  const brandsArray = [];
  let sizesSet = new Set([]);

  filtersDataSubCategoryList.forEach((subcategory) => {
    if (selectedSubCategories.length > 0 && selectedSubCategories.includes(subcategory.subCategory)) {
      subcategory.brandsList.forEach((brand) => {
        brandsArray.push(brand.brand);
        sizesSet = new Set([...sizesSet, ...brand.sizes]);
      });
    }
  });

  const sortedBrands = brandsArray.sort();
  const sortedSizes = Array.from(sizesSet).sort();

  return { sortedBrands, sortedSizes };
};

export const getBrandSizes = (
  filtersDataProductDetails: Record<string, IProduct[]>,
  selectedFilterSizes: string[],
  selectedBrands: string[],
) => {
  const brandSizesArray = [];
  for (const product in filtersDataProductDetails) {
    filtersDataProductDetails[product].forEach((productDetail) => {
      if (selectedFilterSizes.includes(productDetail.size) && selectedBrands.includes(productDetail.brand)) {
        brandSizesArray.push(productDetail.pepProductName);
      }
    });
  }

  //Modify display and selected brandSizes
  const sortedBrandSizes = brandSizesArray.sort();

  return sortedBrandSizes;
};

export const getSizes = (
  filtersDataSubCategoryList: ISubCategoryList[],
  selectedSubCategories: string[],
  selectedBrands: string[],
) => {
  let sizesSet = new Set([]);

  filtersDataSubCategoryList.forEach((subcategory) => {
    if (selectedSubCategories.length > 0 && selectedSubCategories.includes(subcategory.subCategory)) {
      subcategory.brandsList.forEach((brand) => {
        if (selectedBrands.includes(brand.brand)) {
          sizesSet = new Set([...sizesSet, ...brand.sizes]);
        }
      });
    }
  });

  const sortedSizes = Array.from(sizesSet).sort();
  return sortedSizes;
};

export const getFiltersDataPepPeriodsList = (filtersDataPepPeriodsList: string[], pepPeriodSubString: string) => {
  const pepPeriodsList: string[] = [];

  filtersDataPepPeriodsList.forEach((pepPeriod) => {
    if (
      pepPeriod.split("-")[0] === pepPeriodSubString
      // !pepPeriodSubString.includes("2019") &&
      // pepPeriodSubString !== "P13 2021" &&
      // pepPeriodSubString !== "P13 2020"
    ) {
      pepPeriodsList.push(pepPeriod);
    }
  });

  return pepPeriodsList;
};

export const formatFiltersDataPepPeriodsList = (pepPeriodsList: PepPeridod[]) => {
  const pepPeriodsArray: string[] = [];

  pepPeriodsList.forEach((pepPeriod) => {
    pepPeriodsArray.push(pepPeriod.pepPeriod);
  });

  return pepPeriodsArray;
};

export function getSubcategoryList(obj: Record<string, any>): string[] {
  const keys = Object.keys(obj);
  return keys;
}

interface ProductList {
  [key: string]: { pepProductName: string }[];
}

export function getUniqueBuSizes(data: ProductList): string[] {
  const productNames = new Set<string>();

  Object.values(data).forEach((list) => {
    list.forEach((item) => {
      productNames.add(item.pepProductName);
    });
  });

  return Array.from(productNames);
}

export function formatLabel(input: string): string {
  return input
    ?.split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
}
