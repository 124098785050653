import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { emptySplitApi } from "./redux/api/emptySplitApi";
import { rootReducer } from "./redux/rootReducer";
import featureFlagsSlice from "./redux/featureFlags/featureFlagsSlice";

export const store = configureStore({
  reducer: {
    ...rootReducer,
    featureFlags: featureFlagsSlice,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(emptySplitApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
