import { createAsyncThunk } from "@reduxjs/toolkit";
import { CMS_PATHS } from "../../../../constants/textConstants";
import { DrupalResponseData } from "../types/node-data";
import { AxiosResponse } from "axios";
import { apiGet } from "api";
import { CMS_BASE_URL } from "config/config";

export const getReleaseNotes = createAsyncThunk("support/getReleaseNotes", async (_, { rejectWithValue }) => {
  try {
    const { data }: AxiosResponse<DrupalResponseData> = await apiGet({
      url: `${CMS_BASE_URL}${CMS_PATHS.releaseNotes}`,
      params: {
        include: "tags",
      },
    });

    return data;
  } catch (error: any) {
    console.log({ error });
    return rejectWithValue("something went wrong");
  }
});
