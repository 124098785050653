// Local dependencies.
import { IFiltersData } from "types/homeFilters";
import { emptySplitApi } from "../api/emptySplitApi";
import { HOMEPAGE_FILTER_DATA_API_URL } from "constants/apiEndpoints";

// Define service slice and endpoints.
const homePageFiltersDataApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getHomePageFiltersData: builder.query<IFiltersData, void>({
      query: () => HOMEPAGE_FILTER_DATA_API_URL,
      // transformResponse: (response: IFiltersData) => response.sort((a, b) => a.scenarioId - b.scenarioId),
      providesTags: ["HomePageFiltersData"],
    }),
  }),
  overrideExisting: false,
});

// Define hooks.
const { useGetHomePageFiltersDataQuery } = homePageFiltersDataApi;

// Export slice and hooks.
export { homePageFiltersDataApi, useGetHomePageFiltersDataQuery };
