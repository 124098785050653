export const globalHeaderStyle = {
  AppBar: {
    zIndex: (theme: { zIndex: { drawer: number } }) => theme.zIndex.drawer + 1,
    background: 'var(--blue-1)',
    padding: '0.5rem 1rem',
  },
  typography: { fontWeight: 100, fontSize: '1.4rem' },
  bold: {
    fontWeight: 700,
  },
};
