import React from "react";
import { IBrandLadder } from "types/brandLadder";
import { SIZES } from "constants/textConstants";
import { IAuthorizeUser } from "types/authorizeUser";

export const formatNumberFixed = (number, shouldDisplayUnits = true, decimals = 2) => {
  const units = ["", "K", "M", "B"];

  const absNumberFixed = parseFloat(Math.abs(number).toFixed(decimals));

  if (absNumberFixed < 0.01) {
    return "0";
  }

  let unitIndex = 0;
  let value = number;

  while (absNumberFixed >= 1000 && unitIndex < units.length - 1) {
    value /= 1000;
    unitIndex++;
  }

  const f_value = value?.toFixed(decimals);

  return isNaN(value) ? "0.00" : `${f_value}${shouldDisplayUnits ? units[unitIndex] : ""}`;

};

export const formatNumber = (number, shouldDisplayUnits = true, decimals = 2, forceSmallNumber = false) => {
  const units = ["", "K", "M", "B"];
  const absNumber = Math.abs(number);
  const decimalPlaces = decimals;

  for (let i = units.length - 1; i >= 0; i--) {
    const divisor = Math.pow(1000, i);
    if (absNumber >= divisor) {
      return `${(number / divisor).toFixed(decimalPlaces)}${shouldDisplayUnits ? units[i] : ""}`;
    }
  }

  if (forceSmallNumber && absNumber < 0.01) {
    const fixedNumber = number.toFixed(decimalPlaces);
    const difference = Math.floor(Math.log10(fixedNumber) + 1);
    return difference < -3 ? 0 : `${number?.toFixed(decimalPlaces)}`;
  }

  return absNumber < 0.01 ? 0 : `${number?.toFixed(decimalPlaces)}`;
};

export const formatUnit = (number, shouldDisplayUnits = true) => {
  const units = ["", "K", "MM", "B"];
  const decimalPlaces = 2;

  const absNumber = Math.abs(number);

  return `${(absNumber / 1000000).toFixed(decimalPlaces)}${shouldDisplayUnits ? units[2] : ""}`;
};

export const formatPercentage = (number) => {
  const absNumber = Math.abs(number);

  if (absNumber < 1) {
    const rounded = Math.floor(number * 10000) / 100;
    return `${rounded.toFixed(2)}%`;
  } else {
    const rounded = Math.round(number);
    return `${rounded}%`;
  }
};

export const renderReferenceComparison = (kpi: string | number, status: string) => {
  if (typeof kpi !== "number") {
    kpi = parseFloat(kpi);
  }
  return (
    status === "Success" && (
      <span data-testid="reference-comparison" className={kpi >= 0 ? "kpi-positive" : "kpi-negative"}>
        {!Number.isNaN(kpi) && kpi}
        {!Number.isNaN(kpi) ? "%" : " "}
      </span>
    )
  );
};

export const brandLaddersRowIntervals = (startPoint: number, steps: number, stepsDiff: number) => {
  // const rows:{[key:string]: IBrandLadder[]} = {};
  const rows: Record<string, IBrandLadder[]> = {};
  let rowKey: number | string = 0;

  for (let i = 0; i < steps; i++) {
    if (rowKey === 0 && JSON.stringify(rows) === "{}") {
      rowKey = startPoint;
      rows[i + 1] = [{ columnOne: rowKey }];
    } else {
      rowKey = (Number(rowKey) + stepsDiff).toFixed(2);
      rows[i + 1] = [{ columnOne: rowKey }];
    }
  }

  return rows;
};

export const brandLaddersRows = (
  brandLaddersKPIList: IBrandLadder[],
  rows: Record<string, IBrandLadder[]>,
  startPoint: number,
  stepsDiff: number,
  kpiType: "ARP" | "SRP",
  showCompetitors: boolean,
  occasion: string,
) => {
  let maxColumnCount = 0;
  const addRow = (kpi: IBrandLadder) => {
    const currentKpi = kpiType === "ARP" ? kpi.currentArp : kpi.currentSrp;
    const key = Math.trunc((currentKpi - startPoint) / stepsDiff + 1);
    if (rows[key]) {
      rows[key].push(kpi);
      maxColumnCount = maxColumnCount > rows[key].length ? maxColumnCount : rows[key].length;
    }
  };
  // Determine if the record meets the filters
  brandLaddersKPIList.forEach((kpi, index) => {
    if (index !== 0 && kpi.occasion === occasion) {
      if (showCompetitors || (kpi.pepsico && !showCompetitors)) {
        addRow(kpi);
      }
    }
  });

  //sort columns by currentArp OR currentSrp

  for (const row in rows) {
    if (rows[row].length > 0) {
      if (kpiType === "ARP") {
        rows[row].sort((a, b) => {
          return a.currentArp - b.currentArp;
        });
      }
      if (kpiType === "SRP") {
        rows[row].sort((a, b) => {
          return a.currentSrp - b.currentSrp;
        });
      }
    }
  }

  return { rows, maxColumnCount };
};

export const customSort = (a: string, b: string) => {
  const [, pNumA, yearA] = /^P(\d+) (\d+)$/.exec(a);
  const [, pNumB, yearB] = /^P(\d+) (\d+)$/.exec(b);

  // First, compare by year
  if (yearA !== yearB) {
    return parseInt(yearA) - parseInt(yearB);
  }

  // If years are equal, compare by the numeric part of P#
  return parseInt(pNumA) - parseInt(pNumB);
};

export const firstLatterCap = (str: string) => {
  const arr = str.toLowerCase().split(" ");
  let newStr = "";
  if (arr.length) {
    for (let i = 0; i < arr.length; i++) {
      if (SIZES[arr[i]]) {
        newStr += SIZES[arr[i]] + " ";
      } else {
        newStr += arr[i][0].toUpperCase() + arr[i].substring(1, arr[i].length) + " ";
      }
    }
  }

  return newStr;
};

export const getUserProfileInfo = (user: IAuthorizeUser) => {
  if (user === undefined) return;

  const apps = new Set();

  user?.userAccessRoles.forEach((role) => {
    if (role.appId === 1) {
      apps.add("promoai");
    }
    if (role.appId === 2) {
      apps.add("pricingai");
    }
  });

  return apps;
};
