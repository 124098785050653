import React, { useEffect } from "react";

import { Box } from "@mui/system";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";

import { Route, Routes as ReactRoutes } from "react-router-dom";
import Error404 from "modules/fallbacks/Error404";
import { store } from "utils/store";
import { Provider } from "react-redux";
import CombinedContextProvider from "../contexts/CombinedContextProviders";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "hooks/useDebounce";
import MainLayout from "layouts/MainLayout";

interface EmptyPageProps {
  login: () => void;
}

const EmptyPage = ({ login }: EmptyPageProps) => (
  <Box onClick={login}>
    <CircularProgress
      size={40}
      sx={{
        color: "#005CBC",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: "-10px",
        marginLeft: "-10px",
      }}
    />
  </Box>
);

export const OktaClient = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const login = async () => oktaAuth.signInWithRedirect();
  // cleanup
  // const token = JSON.parse(localStorage.getItem(OKTA_TOKEN));
  const debouncedValue = useDebounce(authState?.isAuthenticated, 2000);

  const checkLogin = () => {
    if (authState?.isAuthenticated === false) {
      login();
    }
  };

  useEffect(() => {
    checkLogin();
  }, [debouncedValue]);

  if (!authState?.isAuthenticated) {
    return (
      <ReactRoutes>
        <Route path="*" element={<EmptyPage login={login} />} />
        <Route path="login" element={<LoginCallback />} />
        <Route path="/*" element={<Error404 />} />
      </ReactRoutes>
    );
  }

  return (
    <Provider store={store}>
      <CombinedContextProvider>
        <MainLayout />
      </CombinedContextProvider>
    </Provider>
  );
};
