import { Loader } from "components/shared/Loader/Loader";
import React, { Suspense } from "react";

interface SuspenseLoaderProps {
  children: React.ReactNode;
}

const SuspenseLoader = ({ children }: SuspenseLoaderProps) => {
  return (
    <Suspense
      fallback={
        <Loader
          isLoading={true}
          sx={{
            zIndex: 1050,
          }}
        />
      }
    >
      {children}
    </Suspense>
  );
};

export default SuspenseLoader;
