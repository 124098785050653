// Add third-party dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Local dependencies
import { resultApi } from "utils/redux/Optimization/resultApiSlice";
import { ResultState, ResultApi, GoToScenario } from "types/resultV2";
import { RootState } from "utils/store";

/* =========================================
            Initial State            
==========================================*/
const initialData: ResultApi = {
  outputDetailsByOccasion: [],
  portfolioTotal: null,
  arpScale: null,
  srpScale: null,
};

const initialState: ResultState = {
  scenario: initialData,
  scenario2: initialData,
  reference: initialData,
  negativeAdjustedValues: null,
};

/* =========================================
                Slice         
==========================================*/

const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    editScenarioValue: (state, action: PayloadAction<Partial<GoToScenario>>) => {
      const { payload } = action;

      //Takes record of any negative value
      if (payload.value <= 0) {
        if (state.negativeAdjustedValues === null) {
          state.negativeAdjustedValues = {};
        }
        state.negativeAdjustedValues[payload.id] = payload.value;
      } else {
        if (state.negativeAdjustedValues?.[payload.id]) {
          delete state.negativeAdjustedValues[payload.id];
        }
      }

      if ("occasion" in payload) {
        state.scenario.outputDetailsByOccasion[payload.occasion].children[payload.category].children[
          payload.brand
        ].children[payload.bu_size][payload.kpiName] = payload.value;
      } else {
        state.scenario.outputDetailsByOccasion[payload.category].children[payload.brand].children[payload.bu_size][
          payload.kpiName
        ] = payload.value;
      }
    },
    resetAdjustedScenarioValue: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.scenario.outputDetailsByOccasion.forEach((occasion) => {
        occasion.children.forEach((category) => {
          category.children.forEach((brand) => {
            brand.children.forEach((buSize) => {
              buSize[payload] = null;
            });
          });
        });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(resultApi.endpoints.getResultsV2.matchFulfilled, (state, action) => {
      const { payload } = action;
      const { res, type } = payload;
      if (type === "scenario") {
        state.scenario.outputDetailsByOccasion = res.outputDetailsByOccasion;
        state.scenario.portfolioTotal = res.portfolioTotal;
        state.scenario.arpScale = res.arpScale;
        state.scenario.srpScale = res.srpScale;
        state.scenario.columnInfo = res.columnInfo;
      }
      if (type === "scenario2") {
        state.scenario2.outputDetailsByOccasion = res.outputDetailsByOccasion;
        state.scenario2.portfolioTotal = res.portfolioTotal;
        state.scenario2.arpScale = res.arpScale;
        state.scenario2.srpScale = res.srpScale;
        state.scenario2.columnInfo = res.columnInfo;
      }
      if (type === "reference") {
        state.reference.outputDetailsByOccasion = res.outputDetailsByOccasion;
        state.reference.portfolioTotal = res.portfolioTotal;
        state.reference.arpScale = res.arpScale;
        state.reference.srpScale = res.srpScale;
        state.reference.columnInfo = res.columnInfo;
      }
    });
  },
});

/* =========================================
                Selectors            
==========================================*/
export const scenariosSelector = (state: RootState) => state.result.scenario;
export const scenarios2Selector = (state: RootState) => state.result.scenario2;
export const referencesSelector = (state: RootState) => state.result.reference;
export const negativeAdjustedValuesSelector = (state: RootState) => state.result.negativeAdjustedValues;

/* =========================================
            Actions           
==========================================*/

export const { resetAdjustedScenarioValue, editScenarioValue } = resultSlice.actions;

/* =======================================
           Export Reducer  
===========================================*/
export default resultSlice.reducer;
