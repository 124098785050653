import React from "react";
import { Security } from "@okta/okta-react";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import { useNavigate } from "react-router-dom";
const oktaAuth = new OktaAuth(config.oidc);

interface OktaProviderProps {
  children: JSX.Element ;
}

export const OktaProvider = ({ children }: OktaProviderProps) => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
