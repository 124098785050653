import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { SxProps } from "@mui/system";

interface LoaderProps {
  isLoading: boolean;
  sx?: SxProps;
}

export const Loader = ({ isLoading, sx }: LoaderProps) => (
  <Backdrop open={isLoading} sx={{ color: (theme) => theme.palette.primary.light, ...sx }}>
    <CircularProgress color="inherit" />
  </Backdrop>
);
