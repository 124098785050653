interface SupportLink {
  name: string;
  path: string;
}

export const nestedSupportLinks: SupportLink[] = [
  {
    name: "Release Notes",
    path: "/support/release-notes",
  },
  {
    name: "Known Issues",
    path: "/support/known-issues",
  },
  {
    name: "FAQs",
    path: "/support/faqs",
  },
];
