import React from "react";
import { AppLayout } from "components/materials/containers/AppLayout";

export default function Error404() {
  return (
    <AppLayout>
      <h2>Error 404</h2>
      <p>Page not found</p>
    </AppLayout>
  );
}
