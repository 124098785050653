import { createSlice } from "@reduxjs/toolkit";
import { getKnownIssues } from "./actions/getKnownIssues";
import { getReleaseNotes } from "./actions/getReleaseNotes";
import { SupportState } from "./types/state";
import { getAllFaqs } from "./actions/getAllFaqs";

const initialState: SupportState = {
  faqs: {
    generalNrm: null,
    generalPromoAi: null,
    gettingStarted: null,
    generate: null,
    analyze: null,
    adjust: null,
    generalPricingAi: null,
    admin: null,
    inputs: null,
    outputs: null,
    models: null,
  },
  knownIssues: null,
  releaseNotes: null,
  isLoading: true,
  errorMsg: null,
};

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // FAQs
    builder
      .addCase(getAllFaqs.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getAllFaqs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errorMsg = null;
        state.faqs = action.payload;
      })
      .addCase(getAllFaqs.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload as string;
      });
    // Known Issues
    builder
      .addCase(getKnownIssues.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getKnownIssues.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = null;
        state.knownIssues = payload;
      })
      .addCase(getKnownIssues.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload as string;
      });

    // Release Notes
    builder
      .addCase(getReleaseNotes.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getReleaseNotes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = null;
        state.releaseNotes = payload;
      })
      .addCase(getReleaseNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload as string;
      });
  },
});

export default supportSlice.reducer;
