// local dependencies.
import { emptySplitApi } from "../api/emptySplitApi";
import { FEATURE_FLAG_API_URL } from "constants/apiEndpoints";
import { featureFlagState } from "./interfaces";

// Define service slice and endpoints.
const featureapislice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeatures: builder.query<featureFlagState, void>({
      query: () => FEATURE_FLAG_API_URL,
      providesTags: ["Features"],
    }),
  }),
  overrideExisting: false,
});

// Define hooks.
const { useGetFeaturesQuery } = featureapislice;

// Export slice and hooks.
export { featureapislice, useGetFeaturesQuery };
