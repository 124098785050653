import React, {
  Dispatch,
  FC,
  ReactNode,
  ReactElement,
  createContext,
  SetStateAction,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useGetAuthorizeUserQuery } from "utils/redux/api/authorizeUserApiSlice";
import { IAuthorizeUser } from "types/authorizeUser";

// Set Props type.
type Props = { children: ReactNode };

export type AuthorizeUserType = {
  authorizeUserDetails: IAuthorizeUser;
  setAuthorizeUserDetails: Dispatch<SetStateAction<IAuthorizeUser>>;
};

export const AuthorizeUserContext = createContext<AuthorizeUserType>({
  authorizeUserDetails: null,
  setAuthorizeUserDetails: () => null,
});

const AuthorizeUserContextProvider: FC<Props> = ({ children }): ReactElement => {
  const { data } = useGetAuthorizeUserQuery();
  const [authorizeUserDetails, setAuthorizeUserDetails] = useState<IAuthorizeUser>();

  const hasPromoAccess = (user: IAuthorizeUser) => {
    return user?.userAccessRoles.some((role) => role.appId === 1);
  };

  const isAdmin = (user: IAuthorizeUser) => {
    return user?.userType === 2;
  };

  const userIsPMF = (user: IAuthorizeUser) => {
    const pricingAccessRoles = user?.userAccessRoles.filter((role) => role.appId === 2);
    return pricingAccessRoles.length < 2 && pricingAccessRoles.some((item) => item.marketId === 2);
  };

  useEffect(() => {
    if (data === undefined) return;
    const userData: IAuthorizeUser = {
      ...data,
      userIsPMF: userIsPMF(data),
      hasPromoAccess: hasPromoAccess(data),
      isAdmin: isAdmin(data),
    };
    setAuthorizeUserDetails(userData);
  }, [data]);
  const value = useMemo(() => ({ authorizeUserDetails, setAuthorizeUserDetails }), [authorizeUserDetails]);

  return <AuthorizeUserContext.Provider value={value}>{children}</AuthorizeUserContext.Provider>;
};

export default AuthorizeUserContextProvider;
