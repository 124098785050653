export const MAIN_ROUTES = {
  INDEX: "*",
  SCENARIO: "scenario",
  SCENARIOS: "scenarios/*",
  COMPARISIONS: "comparisons/*",
  RESULTS: "results/*",
  ERROR: "/*",
  ERROR_SCREEN: "/error",
  SIMULATION: "simulation",
  PRICING_SIMULATION: "pricingSimulation",
  SUPPORT: "support/*",
  NO_ACCESS: "/no-access",
};

export const RESULTS_ROUTES = {
  RESULTS: "results",
  DETAILS: "details",
  VOLUME_FLOW: "volume-flow",
  VOLUME_DECOMPOSITION: "volume-decomposition",
  ELASTICITY_CURVES: "elasticity-curves",
  INCENTIVE_CURVES: "incentive-curves",
  BRAND_LADDER: "brand-ladder",
};

export const MANAGE_SCENARIOS_ROUTES = {
  FOLDERS: "folders",
  LIST: "folders/*",
  ARCHIVED: "archived",
};

export const COMPARE_SCENARIOS_ROUTES = {
  COMPARISIONS: "comparisons",
  DETAILS: "details",
  VOLUME_FLOW: "volume-flow",
};

export const SUPPORT_ROUTES = {
  FAQS: "faqs",
  RELEASE_NOTES: "release-notes",
  KNOWN_ISSUES: "known-issues",
};
