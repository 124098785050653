import { selectSpecificFeatureFlag } from "utils/redux/featureFlags/featureFlagsSlice";
import { FeatureFlagsNames } from "utils/redux/featureFlags/interfaces";
import { useAppSelector } from "utils/redux/hooks";

interface IFeatureFlag {
  name: FeatureFlagsNames;
  children: any;
}

const FeatureFlag = ({ name, children }: IFeatureFlag): JSX.Element => {
  const featureFlagEnabled = useAppSelector(selectSpecificFeatureFlag(name));
  return featureFlagEnabled && children;
};

export default FeatureFlag;
