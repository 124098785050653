//Third-party dependencies.
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography, Stack } from "@mui/material";
import { AppLayout } from "components/materials/containers/AppLayout";
import { styled } from "@mui/material/styles";
import maintenanceImage from "assets/images/maintenance_mode.svg";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { maintenanceResponse } from "types/result";
import { IAuthorizeUser } from "types/authorizeUser";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { ENV_UI_OPTIMIZATION } from "config/config";
import { setSuperUser } from "utils/redux/maintenance/maintenanceSlice";
import { useAppDispatch } from "utils/redux/hooks";

const Heading = styled("span")(() => ({
  width: "24.5625rem",
  height: "4.5rem",
  flexgrow: 0,
  fontSize: "3.75rem",
  fontWeight: "bold",
  fontStyle: "normal",
  lineHeight: 1.2,
  letterSpacing: "-0.075rem",
  textAlign: "left",
  color: "var(--black-1)",
  //top: 10px,
  top: "0.625rem",
  position: "relative",
}));

const SupportTextMsg = styled("span")(() => ({
  width: "32.5625rem",
  height: "1.875rem",
  flexGrow: 0,
  fontSize: "1.25rem",
  fontWeight: "normal",
  fontStyle: "normal",
  lineHeight: 1.5,
  letterSpacing: "normal",
  textAlign: "left",
  color: "var(--black-4)",
  top: "1.25rem",
  position: "relative",
}));

const styles = {
  subtext: {
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: "150%",
    color: "var(--black-4)",
  },
  pageBtns: {
    flexDirection: "row",
    gap: "1.5rem",
    marginTop: "0.5rem",
  },
  indvBtn: {
    cursor: "pointer",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5rem",
    color: "var(--blue-21)",
    "& .MuiTypography-root": {
      fontSize: "1.25rem",
      fontWeight: 400,
      textDecoration: "underline",
    },
  },
};

interface IMaintenanceProps {
  data: maintenanceResponse;
  user: IAuthorizeUser;
}

const MaintenancePage = ({ data, user }: IMaintenanceProps) => {
  const [isPromoUser, setIsPromoUser] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      const isPromo = user?.userAccessRoles?.find((element) => {
        return element.appId === 1;
      });

      setIsPromoUser(isPromo === undefined);
    }
  }, [user]);

  const handleSuperUserClick = () => {
    dispatch(setSuperUser(2));
  };

  const handleGetSupportClick = () => {
    window.open("https://forms.office.com/r/9s9s9Wi72j");
  };

  const handlePromoClick = () => {
    window.open(ENV_UI_OPTIMIZATION);
  };

  return (
    <AppLayout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        style={{ minHeight: "30vh", padding: "6.25rem" }}
      >
        <div>
          <img loading="lazy" src={maintenanceImage} alt={"maintenance_mode"} />
          <div>
            <Heading data-testid="heading">
              Exciting enhancements coming <br /> soon…stay tuned.
            </Heading>
          </div>
          <div>
            <SupportTextMsg data-testid="Supporting-text-message">{data?.message}</SupportTextMsg>
          </div>
        </div>

        <Grid style={{ marginTop: "1.5rem" }}>
          <Typography sx={styles.subtext}>
            While we make improvements the product, here are some links that you might find helpful!
          </Typography>
          <Stack sx={styles.pageBtns}>
            <Stack sx={styles.indvBtn}>
              <HelpCenterIcon fontSize="medium" style={{ verticalAlign: "middle" }} />
              <Typography onClick={handleGetSupportClick}>Get Support</Typography>
            </Stack>
            {!data?.apps?.includes("PromoAi") && isPromoUser ? (
              <Stack sx={styles.indvBtn}>
                <DateRangeIcon fontSize="medium" style={{ verticalAlign: "middle" }} />
                <Typography onClick={handlePromoClick} data-testid="promo">
                  Promo.AI
                </Typography>
              </Stack>
            ) : null}
            <Stack sx={styles.indvBtn}>
              <GppGoodIcon fontSize="medium" style={{ verticalAlign: "middle" }} />
              <Typography onClick={handleSuperUserClick}>Superuser</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default MaintenancePage;
