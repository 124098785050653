import { createTheme } from "@mui/material";
import "@mui/material/styles";
import "./colors.css";
import "./pricingAiColors.css";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h6Heading: React.CSSProperties;
    label: React.CSSProperties;
    isMandatory: React.CSSProperties;
    adornmentIcon: React.CSSProperties;
    placeholder: React.CSSProperties;
    dropdownItem: React.CSSProperties;
    dropdownItemSelected: React.CSSProperties;
    disabled: React.CSSProperties;
    enabled: React.CSSProperties;
    growth: React.CSSProperties;
    loss: React.CSSProperties;
    xSmall: React.CSSProperties;
    xxSmall: React.CSSProperties;
    small: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h6Heading?: React.CSSProperties;
    label?: React.CSSProperties;
    isMandatory?: React.CSSProperties;
    adornmentIcon?: React.CSSProperties;
    placeholder?: React.CSSProperties;
    dropdownItem?: React.CSSProperties;
    dropdownItemSelected?: React.CSSProperties;
    disabled?: React.CSSProperties;
    enabled?: React.CSSProperties;
    growth?: React.CSSProperties;
    loss?: React.CSSProperties;
    xSmall?: React.CSSProperties;
    xxSmall?: React.CSSProperties;
    small?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h6Heading: true;
    label: true;
    isMandatory: true;
    adornmentIcon: true;
    placeholder: true;
    dropdownItem: true;
    dropdownItemSelected: true;
    disabled: true;
    enabled: true;
    growth: true;
    loss: true;
    xSmall: true;
    xxSmall: true;
    small: true;
  }
}

// define typographies
const font400 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  fontSize: "1.4rem",
};

const font700 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "140%",
  fontSize: "1.4rem",
};

const xxSmall = {
  ...font700,
  fontSize: "0.625rem",
};

const xSmall = {
  ...font400,
  fontSize: "0.75rem",
};

const small = {
  ...font400,
  fontSize: "0.875rem",
};

// const cssVar = (name) => getComputedStyle(document.documentElement).getPropertyValue(name).trim();

export const theme = createTheme({
  palette: {
    primary: {
      main: "#005cbc",
      light: "#005cbc",
      dark: "#005cbc",
      // contrastText: "#005cbc",
    },
    secondary: {
      // semantic-colors-states-error: #8c0733
      // main: cssVar("--red-1"),
      main: "#8c0733",
    },
    error: {
      // main: cssVar("--red-1"),
      main: "#8c0733",
    },
  },
  typography: {
    fontFamily: ["Inter", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
    h6Heading: {
      ...font700,
      fontSize: "1.125rem",
      color: "var(--pricing-gray-4)",
    },
    xSmall: xSmall,
    xxSmall: xxSmall,
    small: small,
    label: {
      ...xSmall,
      color: "var(--pricing-gray-4)",
    },
    isMandatory: {
      ...xxSmall,
      textAlign: "right",
      color: "var(--pricing-gray-4)",
    },
    adornmentIcon: {
      ...small,
      color: "var(--pricing-gray-5)",
    },
    placeholder: {
      ...small,
      color: "var(--pricing-gray-3)",
    },
    dropdownItem: {
      ...small,
      color: "var(--pricing-gray-4)",
    },
    dropdownItemSelected: {
      ...small,
      color: "var(--pricing-gray-5)",
    },
    disabled: {
      ...font700,
      fontSize: "0.875rem",
      color: "var(--pricing-gray-3)",
    },
    enabled: {
      ...font700,
      fontSize: "0.875rem",
      color: "var(--pricing-white-1)",
    },
    growth: {
      ...font700,
      fontSize: "0.875rem",
      color: "var(--pricing-green-1)",
    },
    loss: {
      ...font700,
      fontSize: "0.875rem",
      color: "var(--pricing-red-1)",
    },
  },
});
