// Add third-party dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Local dependencies
import { RootState } from "utils/store";

import { IUserType, IUserTypeState } from "types/authorizeUser";

/* =========================================
            Initial State            
==========================================*/
const initialData: IUserType = {
  userType: null,
  superUser: false,
};

const initialState: IUserTypeState = {
  isSuperUser: initialData,
};

const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setSuperUser: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const userType = payload.valueOf();
      if (userType === 2) {
        state.isSuperUser.superUser = true;
        state.isSuperUser.userType = userType;
      }
    },
  },
});

/* =========================================
                Selectors            
==========================================*/
export const isSuperUserSelector = (state: RootState) => state.maintenance.isSuperUser;

/* =========================================
            Actions           
==========================================*/

export const { setSuperUser } = maintenanceSlice.actions;

/* =======================================
           Export Reducer  
===========================================*/

export default maintenanceSlice.reducer;
