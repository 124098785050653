import React, { useContext, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import PricingIcon from "assets/icons/PricingIcon";
import PepsiCoLogoIcon from "assets/icons/PepsiCoLogoIcon";
import { AuthorizeUserContext } from "contexts/AuthorizeUserContext";
import MainDropdownMenu from "components/menus/MainDropdownMenu";
import { globalHeaderStyle } from "./globalHeaderStyle";

const GlobalHeader = () => {
  const { authorizeUserDetails: user } = useContext(AuthorizeUserContext);

  const userName = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return user?.firstName.length > 10 || user?.lastName.length > 10
        ? user?.firstName
        : `${user?.firstName} ${user?.lastName}`;
    }
    return "User";
  }, [user?.firstName, user?.lastName]);

  const userNameInitial = useMemo(() => {
    return user?.firstName && user?.lastName
      ? `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`
      : "US";
  }, [user?.firstName, user?.lastName]);

  return (
    <AppBar position="fixed" sx={globalHeaderStyle.AppBar}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <PepsiCoLogoIcon sx={{ height: "2.376rem", width: "10rem" }} />
        <Stack direction="row" alignItems="center" spacing={6}>
          <PricingIcon /> <span style={{ fontWeight: 700 }}>PRICING AI</span>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography sx={globalHeaderStyle.typography}>
            Welcome Back,<b style={globalHeaderStyle.bold}> {userName}</b>
          </Typography>

          <MainDropdownMenu userNameInitial={userNameInitial} />
        </Stack>
      </Stack>
    </AppBar>
  );
};

export default GlobalHeader;
