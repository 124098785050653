import { OktaAuthOptions } from "@okta/okta-auth-js";
import { ENV_OKTA_CLIENT_ID, ENV_OKTA_ISSUER, ENV_UI_URL } from "config/config";

const CLIENT_ID = ENV_OKTA_CLIENT_ID;
const ISSUER = ENV_OKTA_ISSUER;
const REDIRECT_URI = `${ENV_UI_URL}/login`;
const LOGOUT_URI = `${ENV_UI_URL}/logout`;

const oidc: OktaAuthOptions = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  postLogoutRedirectUri: LOGOUT_URI,
};
export default {
  oidc,
};
