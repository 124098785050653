export const mainDropdownMenuStyle = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  iconButton: { ml: 1 },
  avatar: {
    bgcolor: 'var(--white-1)',
    color: 'var(--blue-1)',
    width: '3.4rem',
    height: '3.4rem',
    fontSize: '2rem',
  },
  menu: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1,
    minWidth: 200,
    '& .MuiAvatar-root': {
      width: 25,
      height: 25,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },

  menuItem: {
    ':hover': { bgcolor: 'var(--gray-1)' },
  },
};
