import homeFiltersReducer from "utils/redux/Home/homeFilters";
import resultReducer from "utils/redux/Optimization/resultSlice";
import supportReducer from "./support/supportSlice";
import maintenanceSlice from "utils/redux/maintenance/maintenanceSlice";

export const rootReducer = {
  homeFilters: homeFiltersReducer,
  result: resultReducer,
  support: supportReducer,
  maintenance: maintenanceSlice,
};
