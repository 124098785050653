/**
 * © 2023 Boston Consulting Group
 * BCG X
 * Shon Little
 * Created: 2023-01-30
 */

// Add third-party dependencies.
import React, { FC, ReactNode, ReactElement, Dispatch, SetStateAction, createContext, useState, useMemo } from "react";

// Add local dependencies.
import type { Column } from "../types/Column";
import columns from "./columns";

// Set Props type.
type Props = { children: ReactNode };

// Create context.
export const ColumnContext = createContext<{
  checkboxes: Column[];
  setCheckboxes: Dispatch<SetStateAction<Column[]>>;
}>({
  checkboxes: [],
  setCheckboxes: () => null,
});

/**
 * ColumnContextProvider component.
 * @example
 * const children = (<div>Hello</div>)
 * return (
 *   <ColumnContextProvider children={children} \>
 * )
 * @param {ReactNode} children - child components.
 * @returns {ReactElement} component.
 */
const ColumnContextProvider: FC<Props> = ({ children }): ReactElement => {
  // Set state hooks.
  const [checkboxes, setCheckboxes] = useState<Column[]>(columns);

  // Memo hooks.
  const value = useMemo(() => ({ checkboxes, setCheckboxes }), [checkboxes]);

  // Render component.
  return <ColumnContext.Provider value={value}>{children}</ColumnContext.Provider>;
};

// Export component.
export default ColumnContextProvider;
