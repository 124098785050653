import { createTheme } from "@mui/material/styles";
import themeCommonSettings from "./themeCommonSettings";
import "./colors.css";

const theme = createTheme(
  {
    palette: {
      primary: {
        light: "#005CBC",
        main: "#616161",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#005cbc",
        contrastText: "#161616",
      },
    },
    custom: {
      // Add custom colors for results details table.
      detailsTable: {
        level0: "#99BEE4",
        level1: "#CCDEF2",
        level2: "#FFFFFF",

        // level1: "#B3CEEB",
        // level2: "#CCDEF2",

        level3: "#E6EFF8",
        level4: "#FBFCFE",
        level5: "#B3CEEB",
      },
      trending: {
        up: "#006A42",
        down: "#AA001A",
      },
      volumeFlow: {
        lightBlue: "#EFF5FC",
        darkBlue: "#003771",
        lightOrange: "#FFF2D2",
        darkOrange: "#FF891C",
        lightRed: "#FAE5E5",
        darkRed: "#AA001A",
        lightGreen: "#E5F2EE",
        darkGreen: "#006A42",
        lightGrey: "#D0D0D0",
        darkGrey: "#3A3A3A",
      },
      elasticityCurves: {
        series: ["#005CBC", "#44545A", "#B05788", "#0096D1", "#E56679", "#008553"],
      },
      incentiveCurves: {
        series: ["#005CBC", "#005CBC", "#818181", "#818181"],
        lightGrey: "#FBFCFE",
        mediumGrey: "#616161",
        darkGrey: "#3A3A3A",
        darkBlue: "#005CBC",
        mediumBlue: "#DFEAF9",
        lightBlue: "#EFF5FC",
        lightOffWhite: "#F3F7FD",
        darkOffWhite: "#D0D0D0",
      },
    },
    typography: {
      fontFamily: "Inter",
    },
  },
  themeCommonSettings,
);

export default theme;
