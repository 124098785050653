// Add third-party dependencies.
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "utils/store";
import { homePageFiltersDataApi } from "./homePageFiltersData";
import { customSort } from "utils/helpers/Helpers";
import { IChannel, IFilter, IInitialStateTypes } from "types/homeFilters";
import { getBrandsAndSizes, getBrandSizes, getSizes } from "utils/helpers/homeFilterHelpers";

/* =========================================
            initial State           
==========================================*/
const initFilter: IFilter = {
  default: [],
  selected: [],
  display: [],
};
const initialState: IInitialStateTypes = {
  filtersData: {
    geography: "",
    markets: [],
    channels: [],
    category: "",
    subCategoryList: [],
    productDetails: null,
    manufacturerList: [],
    pepPeriodsList: [],
  },
  filterChannel: {
    ...initFilter,
  },
  filterMarket: {
    ...initFilter,
  },
  filterBrand: {
    ...initFilter,
  },
  filterBrandSize: {
    ...initFilter,
  },
  filterCompetitors: {
    ...initFilter,
  },
  filterManufacturer: {
    ...initFilter,
  },
  filterPepPeriod: {
    ...initFilter,
  },
  filterSizes: {
    ...initFilter,
  },
  filterSubcategory: {
    ...initFilter,
  },
  competitorList: [],
  pepProductsList: [],
  channelWithMarketId: [],
};

/* =========================================
            Slice           
==========================================*/
const homeFiltersSlice = createSlice({
  name: "homeFilters",
  initialState,
  reducers: {
    setFilterChannel: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterChannel = payload;
    },
    setFilterMarket: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterMarket = payload;
    },
    setFilterSubcategory: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterSubcategory = payload;

      //Brand and Sizes updated when changing subcategory filter
      const selectedSubCategories = state.filterSubcategory.selected;
      const filtersDataSubCategoryList = state.filtersData.subCategoryList;
      const { sortedBrands, sortedSizes } = getBrandsAndSizes(filtersDataSubCategoryList, selectedSubCategories);

      //Modify display and selected brands only
      state.filterBrand.display = sortedBrands;
      state.filterBrand.selected = sortedBrands;

      //Modify display and selected sizes only
      state.filterSizes.display = sortedSizes;
      state.filterSizes.selected = sortedSizes;

      //Brand/Size updated when changing subcategory filter
      const filtersDataProductDetails = state.filtersData.productDetails;
      const selectedFilterSizes = state.filterSizes.selected;
      const selectedBrands = state.filterBrand.selected;
      const sortedBrandSizes = getBrandSizes(filtersDataProductDetails, selectedFilterSizes, selectedBrands);

      //Modify display and selected brandSizes
      state.filterBrandSize.display = sortedBrandSizes;
      state.filterBrandSize.selected = sortedBrandSizes;
    },
    setFilterManufacturer: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterManufacturer = payload;
    },
    setFilterBrand: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterBrand = payload;

      //Size updated when changing brand filter
      const filtersDataSubCategoryList = state.filtersData.subCategoryList;
      const selectedSubCategories = state.filterSubcategory.selected;
      const selectedBrands = state.filterBrand.selected;
      const sortedSizes = getSizes(filtersDataSubCategoryList, selectedSubCategories, selectedBrands);

      // Modify display and selected sizes
      state.filterSizes.display = sortedSizes;
      state.filterSizes.selected = sortedSizes;

      //Brand/Size updated when changing brand filter
      const filtersDataProductDetails = state.filtersData.productDetails;
      const selectedFilterSizes = state.filterSizes.selected;
      const sortedBrandSizes = getBrandSizes(filtersDataProductDetails, selectedFilterSizes, selectedBrands);

      // Modify display and selected brandSizes
      state.filterBrandSize.display = sortedBrandSizes;
      state.filterBrandSize.selected = sortedBrandSizes;
    },
    setFilterSizes: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterSizes = payload;

      //Brand/Size updated when changing size filter
      const filtersDataProductDetails = state.filtersData.productDetails;
      const selectedFilterSizes = state.filterSizes.selected;
      const selectedBrands = state.filterBrand.selected;
      const sortedBrandSizes = getBrandSizes(filtersDataProductDetails, selectedFilterSizes, selectedBrands);

      //Modify display and selected brandSizes
      state.filterBrandSize.display = sortedBrandSizes;
      state.filterBrandSize.selected = sortedBrandSizes;
    },
    setFilterBrandSize: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterBrandSize = payload;
    },
    setFilterPepPeriod: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterPepPeriod = payload;
    },
    setFilterCompetitors: (state, action: PayloadAction<IFilter>) => {
      const { payload } = action;
      state.filterCompetitors = payload;
    },
    setCompetitorList: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      state.competitorList = payload;
    },
    setPepProductsList: (state, action: PayloadAction<string[]>) => {
      const { payload } = action;
      state.pepProductsList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(homePageFiltersDataApi.endpoints.getHomePageFiltersData.matchFulfilled, (state, action) => {
      const { payload } = action;
      const brandsSet = new Set([]);
      let sizesSet = new Set([]);
      let brandSizeArray = [];
      const competitorsArray = [];
      const pepPeriodsSet = new Set<string>();
      const channelsArray = [];
      const channelsWithMarketId: IChannel[] = [];
      let manufacturersArray = [];

      payload.channels.forEach((channel) => {
        channelsArray.push(channel.channelName);
        channelsWithMarketId.push(channel);
      });

      const subcategoryDefault = [];
      payload.subCategoryList.forEach((subcategory) => {
        subcategoryDefault.push(subcategory.subCategory);
        subcategory.brandsList.forEach((brand) => {
          brandsSet.add(brand.brand);
          sizesSet = new Set([...sizesSet, ...brand.sizes]);
        });
      });
      for (const product in payload.productDetails) {
        // eslint-disable-next-line no-loop-func
        payload.productDetails[`${product}`].forEach((pepProductName) => {
          brandSizeArray = [...brandSizeArray, pepProductName.pepProductName];
        });
      }

      manufacturersArray = payload.manufacturerList;
      manufacturersArray.sort();
      state.filterManufacturer = {
        default: manufacturersArray,
        selected: manufacturersArray,
        display: manufacturersArray,
      };

      payload.pepPeriodsList.forEach((period) => {
        const tempPeriod = period.split("-")[0];
        pepPeriodsSet.add(tempPeriod);
      });

      const pepPeriodsArray = Array.from(pepPeriodsSet);

      const sortedMarket = payload.markets.sort();
      state.filterMarket = {
        default: sortedMarket,
        selected: sortedMarket,
        display: sortedMarket,
      };

      state.filtersData = payload;
      subcategoryDefault.sort();
      state.filterSubcategory = {
        default: subcategoryDefault,
        selected: subcategoryDefault,
        display: subcategoryDefault,
      };

      const sortedBrands = Array.from(brandsSet).sort();
      state.filterBrand = {
        default: sortedBrands,
        selected: sortedBrands,
        display: sortedBrands,
      };

      const sizesArray = Array.from(sizesSet).sort();
      sizesArray.sort();
      state.filterSizes = {
        default: sizesArray,
        selected: sizesArray,
        display: sizesArray,
      };

      brandSizeArray.sort();
      state.filterBrandSize = {
        default: brandSizeArray,
        selected: brandSizeArray,
        display: brandSizeArray,
      };

      pepPeriodsArray.sort(customSort);
      state.filterPepPeriod = {
        default: pepPeriodsArray,
        selected: pepPeriodsArray,
        display: pepPeriodsArray,
      };

      competitorsArray.sort();
      state.filterCompetitors = {
        default: competitorsArray,
        selected: competitorsArray,
        display: competitorsArray,
      };

      channelsArray.sort();
      state.filterChannel = {
        default: channelsArray,
        selected: channelsArray,
        display: channelsArray,
      };

      state.channelWithMarketId = channelsWithMarketId;

      state.competitorList = competitorsArray.map((prod) => {
        return prod.split("-", 1)[0].toUpperCase();
      });
      state.pepProductsList = brandSizeArray;
    });
  },
});

/* =========================================
                Selectors            
==========================================*/
export const stateFiltersData = (state: RootState) => state.homeFilters.filtersData;
export const stateFilterChannel = (state: RootState) => state.homeFilters.filterChannel;
export const stateFilterMarket = (state: RootState) => state.homeFilters.filterMarket;
export const stateFilterBrand = (state: RootState) => state.homeFilters.filterBrand;
export const stateFilterBrandSize = (state: RootState) => state.homeFilters.filterBrandSize;
export const stateFilterCompetitors = (state: RootState) => state.homeFilters.filterCompetitors;
export const stateFilterManufacturer = (state: RootState) => state.homeFilters.filterManufacturer;
export const stateFilterPepPeriod = (state: RootState) => state.homeFilters.filterPepPeriod;
export const stateFilterSizes = (state: RootState) => state.homeFilters.filterSizes;
export const stateFilterSubcategory = (state: RootState) => state.homeFilters.filterSubcategory;
export const stateCompetitorList = (state: RootState) => state.homeFilters.competitorList;
export const statePepProductsList = (state: RootState) => state.homeFilters.pepProductsList;
export const stateChannelWithMarketId = (state: RootState) => state.homeFilters.channelWithMarketId;

// Creates memoized selector
export const selectDefaultFilteredChannel = createSelector(
  stateFilterChannel,
  (filteredChannel) => filteredChannel.default,
);
export const selectDisplayFilteredChannel = createSelector(
  stateFilterChannel,
  (filteredChannel) => filteredChannel.display,
);
export const selectSelectedFilteredChannel = createSelector(
  stateFilterChannel,
  (filteredChannel) => filteredChannel.selected,
);
export const selectDefaultFilteredMarket = createSelector(
  stateFilterMarket,
  (filteredMarket) => filteredMarket.default,
);
export const selectDisplayFilteredMarket = createSelector(
  stateFilterMarket,
  (filteredMarket) => filteredMarket.display,
);
export const selectSelectedFilteredMarket = createSelector(
  stateFilterMarket,
  (filteredMarket) => filteredMarket.selected,
);
export const selectDefaultFilteredBrand = createSelector(stateFilterBrand, (filteredBrand) => {
  return filteredBrand.default;
});
export const selectDisplayFilteredBrand = createSelector(stateFilterBrand, (filteredBrand) => filteredBrand.display);
export const selectSelectedFilteredBrand = createSelector(stateFilterBrand, (filteredBrand) => filteredBrand.selected);
export const selectDefaultFilterBrandSize = createSelector(
  stateFilterBrandSize,
  (filterBrandSize) => filterBrandSize.default,
);
export const selectDisplayFilterBrandSize = createSelector(
  stateFilterBrandSize,
  (filterBrandSize) => filterBrandSize.display,
);
export const selectSelectedFilterBrandSize = createSelector(
  stateFilterBrandSize,
  (filterBrandSize) => filterBrandSize.selected,
);
export const selectDefaultFilterCompetitors = createSelector(
  stateFilterCompetitors,
  (filterCompetitors) => filterCompetitors.default,
);
export const selectDisplayFilterCompetitors = createSelector(
  stateFilterCompetitors,
  (filterCompetitors) => filterCompetitors.display,
);
export const selectSelectedFilterCompetitors = createSelector(
  stateFilterCompetitors,
  (filterCompetitors) => filterCompetitors.selected,
);
export const selectDefaultFilterManufacturer = createSelector(
  stateFilterManufacturer,
  (filterManufacturer) => filterManufacturer.default,
);
export const selectDisplayFilterManufacturer = createSelector(
  stateFilterManufacturer,
  (filterManufacturer) => filterManufacturer.display,
);
export const selectSelectedFilterManufacturer = createSelector(
  stateFilterManufacturer,
  (filterManufacturer) => filterManufacturer.selected,
);
export const selectDefaultFilterPepPeriod = createSelector(
  stateFilterPepPeriod,
  (filterPepPeriod) => filterPepPeriod.default,
);
export const selectDisplayFilterPepPeriod = createSelector(
  stateFilterPepPeriod,
  (filterPepPeriod) => filterPepPeriod.display,
);
export const selectSelectedFilterPepPeriod = createSelector(
  stateFilterPepPeriod,
  (filterPepPeriod) => filterPepPeriod.selected,
);
export const selectDefaultFilterSizes = createSelector(stateFilterSizes, (filterSizes) => filterSizes.default);
export const selectDisplayFilterSizes = createSelector(stateFilterSizes, (filterSizes) => filterSizes.display);
export const selectSelectedFilterSizes = createSelector(stateFilterSizes, (filterSizes) => filterSizes.selected);
export const selectDefaultFilterSubcategory = createSelector(
  stateFilterSubcategory,
  (filterSubcategory) => filterSubcategory.default,
);
export const selectDisplayFilterSubcategory = createSelector(
  stateFilterSubcategory,
  (filterSubcategory) => filterSubcategory.display,
);
export const selectSelectedFilterSubcategory = createSelector(
  stateFilterSubcategory,
  (filterSubcategory) => filterSubcategory.selected,
);

/* =========================================
            Actions           
==========================================*/
export const {
  setFilterChannel,
  setFilterSubcategory,
  setFilterManufacturer,
  setFilterBrand,
  setFilterSizes,
  setFilterBrandSize,
  setFilterPepPeriod,
  setFilterCompetitors,
  setCompetitorList,
  setPepProductsList,
  setFilterMarket,
} = homeFiltersSlice.actions;
/* =======================================
           Export Reducer  
===========================================*/
export default homeFiltersSlice.reducer;
