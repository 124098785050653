import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { featureapislice } from "./featureFlagSplitApi";
import { FeatureFlagsNames, featureFlagState } from "./interfaces";
import { RootState } from "utils/store";

// Estado inicial
const initialState: featureFlagState = {
  features: [],
  loading: false,
  errorMessage: "",
};

// Crea el slice
const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<featureFlagState>) => {
      state.features = action.payload.features;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(featureapislice.endpoints.getFeatures.matchPending, (state) => {
      state.loading = true;
      state.errorMessage = "";
    });
    builder.addMatcher(featureapislice.endpoints.getFeatures.matchFulfilled, (state, action) => {
      state.features = action.payload.features;
      state.loading = false;
      state.errorMessage = "";
    });
    builder.addMatcher(featureapislice.endpoints.getFeatures.matchRejected, (state, action) => {
      state.loading = false;
      state.features = [];
      state.errorMessage = action.error.message ?? "";
    });
  },
});
/* =========================================
                Selectors            
==========================================*/
export const selectFeatureFlags = (state: RootState) => state.featureFlags.features;

export const selectSpecificFeatureFlag = (featureFlagName: FeatureFlagsNames) =>
  createSelector([selectFeatureFlags], (featureFlags) => {
    const featureFlag = featureFlags.find((flag) => flag.name === featureFlagName);
    return featureFlag?.enabled ?? false;
  });

/* =========================================
            Actions           
==========================================*/
export const { setFeatureFlags } = featureFlagsSlice.actions;

/* =======================================
           Export Reducer  
===========================================*/
export default featureFlagsSlice.reducer;
