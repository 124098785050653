import React from "react";
export interface IIcon {
    width?: string;
    height?: string;
    className?: string;
    viewBox?: string;
    white : boolean;
    onClick: () => void
}

const ExitNotificationIcon = ({ white , width, height, className, onClick }: IIcon) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width || 24}
            height={height || 24} 
            className={className || ""}
            viewBox="0 -960 960 960" 
            onClick={onClick}
            >
                <path
                    style={{fill: white ? "#ffffff" : "#3A3A3A", cursor:"pointer" }}
                    d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
        </svg>
  );
};

export default ExitNotificationIcon;