/**
 * © 2023 Boston Consulting Group
 * BCG X
 * Shon Little
 * Created: 2023-02-06
 */

// Add local dependencies.
import AddScenarioContextProvider from "./AddScenarioContext";
import AuthorizeUserContextProvider from "./AuthorizeUserContext";
import CombineContextComponents from "./CombineContextComponents";
import ColumnContextProvider from "./ColumnContext";
import CreateScenarioDisplayContextProvider from "./CreateScenarioDisplayContext";
import FolderDisplayContextProvider from "./FolderDisplayContext";
import ScenarioDisplayContextProvider from "./ScenarioDisplayContext";
import NotificationContextProvider from "./NotificationContext";

/**
 * CombinedContextProviders component.
 * @component
 * @example
 * return (
 *   <CombinedContextProviders />
 * )
 * @returns {React.ReactElement} component.
 */
const CombinedContextProviders = CombineContextComponents(
  AddScenarioContextProvider,
  AuthorizeUserContextProvider,
  ColumnContextProvider,
  CreateScenarioDisplayContextProvider,
  FolderDisplayContextProvider,
  ScenarioDisplayContextProvider,
  NotificationContextProvider
);

export default CombinedContextProviders;
