// Add third-party dependencies.
import React, { FC, ReactNode, ReactElement, Dispatch, SetStateAction, createContext, useState, useMemo } from "react";

// Set Props type.
type Props = { children: ReactNode };

export type NotificationSeverity = "success" | "warning" | "error" | "info";

export interface INotification {
  title: string;
  textMessage: string;
  severity: NotificationSeverity;
  action?: { label: string; onClick: () => void };
}

// Create context.
export const NotificationContext = createContext<{
  displayNotification: boolean;
  setDisplayNotification: Dispatch<SetStateAction<boolean>>;
  notificationInfo: INotification;
  setNotificationInfo: Dispatch<SetStateAction<INotification>>;
  toastNotification: (notification: INotification) => void;
}>({
  displayNotification: false,
  setDisplayNotification: () => null,
  notificationInfo: { title: "Tittle", textMessage: "Text Message", severity: "success" },
  setNotificationInfo: () => null,
  toastNotification: () => null,
});

/**
 * ColumnContextProvider component.
 * @example
 * const children = (<div>Hello</div>)
 * return (
 *   <ColumnContextProvider children={children} \>
 * )
 * @param {ReactNode} children - child components.
 * @returns {ReactElement} component.
 */
const NotificationContextProvider: FC<Props> = ({ children }): ReactElement => {
  // Set state hooks.
  const [displayNotification, setDisplayNotification] = useState<boolean>(false);
  const [notificationInfo, setNotificationInfo] = useState<INotification>({
    title: "Notification",
    textMessage: "Mensaje",
    severity: "success",
  });

  const toastNotification = ({ title, textMessage, severity, action }: INotification) => {
    setNotificationInfo({ title, textMessage, severity, action });
    setDisplayNotification(true);
  };

  // Memo hooks.
  const value = useMemo(
    () => ({ displayNotification, setDisplayNotification, notificationInfo, setNotificationInfo, toastNotification }),
    [displayNotification, notificationInfo],
  );

  // Render component.
  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

// Export component.
export default NotificationContextProvider;
