/**
 * © 2023 Boston Consulting Group
 * BCG X
 * Shon Little
 * Created: 2023-01-30
 */

const columns = [
  {
    id: 0,
    label: "SubCat",
    resultHeaderIds: [77, 78],
    resultColumns: ["subCat"],
    compareHeaderIds: [],
    compareColumns: [],
    manualHeaderIds: [],
    manualColumns: [""],
    checked: false,
  },
  {
    id: 1,
    label: "Observed Base Price",
    resultHeaderIds: [2, 3, 4, 36, 37, 38],
    resultColumns: ["rsp_reference", "rsp_scenario", "rsp_adjusted", "rsp_delta", "rsp_diff"],
    compareHeaderIds: [2, 16, 17, 18],
    compareColumns: ["rsp_reference", "rsp_scenario1", "rsp_scenario2"],
    manualHeaderIds: [2, 14, 29],
    manualColumns: ["rsp_reference", "rsp_adjusted"],
    checked: true,
  },
  {
    id: 2,
    label: "SRP",
    resultHeaderIds: [6, 7, 8, 39, 40],
    resultColumns: ["srp_reference", "srp_scenario", "srp_delta", "srp_diff"],
    compareHeaderIds: [3, 19, 20, 21],
    compareColumns: ["srp_reference", "srp_scenario1", "srp_scenario2"],
    manualHeaderIds: [5, 35],
    manualColumns: ["srp_reference"],
    checked: true,
  },
  {
    id: 3,
    label: "SDV",
    resultHeaderIds: [60, 61, 62, 63, 64],
    resultColumns: ["sdv_reference", "sdv_scenario", "sdv_delta", "sdv_diff"],
    compareHeaderIds: [4, 22, 23, 24],
    compareColumns: ["sdv_reference", "sdv_scenario1", "sdv_scenario2"],
    manualHeaderIds: [],
    manualColumns: ["sdv_reference"],
    checked: true,
  },
  {
    id: 4,
    label: "BPE",
    resultHeaderIds: [5, 59],
    resultColumns: ["bpe"],
    compareHeaderIds: [58, 60, 61, 62],
    compareColumns: ["bpe_reference", "bpe_scenario1", "bpe_scenario2"],
    manualHeaderIds: [5],
    manualColumns: ["bpe"],
    checked: true,
  },
  {
    id: 5,
    label: "Weight Out (oz)",
    resultHeaderIds: [75, 76],
    resultColumns: ["weight"],
    compareHeaderIds: [59, 63, 64, 65],
    compareColumns: ["weight_reference", "weight_scenario1", "weight_scenario2"],
    manualHeaderIds: [],
    manualColumns: ["weight"],
    checked: true,
  },
  {
    id: 6,
    label: "ARP",
    resultHeaderIds: [9, 10, 11, 41, 42],
    resultColumns: ["arp_reference", "arp_scenario", "arp_delta", "arp_diff"],
    compareHeaderIds: [5, 25, 26, 27],
    compareColumns: ["arp_reference", "arp_scenario1", "arp_scenario2"],
    manualHeaderIds: [17, 48],
    manualColumns: ["arp_reference"],
    checked: true,
  },
  {
    id: 7,
    label: "ARP/OZ",
    resultHeaderIds: [65, 66, 67, 68, 69],
    resultColumns: ["arp_oz_reference", "arp_oz_scenario", "arp_oz_delta", "arp_oz_diff"],
    compareHeaderIds: [6, 28, 29, 30],
    compareColumns: ["arp_oz_reference", "arp_oz_scenario1", "arp_oz_scenario2"],
    manualHeaderIds: [],
    manualColumns: ["arp_oz_reference"],
    checked: false,
  },
  {
    id: 8,
    label: "RSV",
    resultHeaderIds: [18, 19, 20, 47, 48],
    resultColumns: ["rsv_reference", "rsv_scenario", "rsv_delta", "rsv_diff"],
    compareHeaderIds: [10, 40, 41, 42],
    compareColumns: ["rsv_reference", "rsv_scenario1", "rsv_scenario2"],
    manualHeaderIds: [6, 36],
    manualColumns: ["rsv_reference"],
    checked: true,
  },
  {
    id: 9,
    label: "Ticket",
    resultHeaderIds: [70, 71, 72, 73, 74],
    resultColumns: ["ticket_reference", "ticket_scenario", "ticket_delta", "ticket_diff"],
    compareHeaderIds: [11, 43, 44, 45],
    compareColumns: ["ticket_reference", "ticket_scenario1", "ticket_scenario2"],
    manualHeaderIds: [],
    manualColumns: ["ticket_reference"],
    checked: true,
  },
  {
    id: 10,
    label: "Net Revenue",
    resultHeaderIds: [15, 16, 17, 45, 46],
    resultColumns: ["net_revenue_reference", "net_revenue_scenario", "net_revenue_delta", "net_revenue_diff"],
    compareHeaderIds: [13, 49, 50, 51],
    compareColumns: ["net_revenue_reference", "net_revenue_scenario1", "net_revenue_scenario2"],
    manualHeaderIds: [20, 39],
    manualColumns: ["net_revenue_reference"],
    checked: true,
  },
  {
    id: 11,
    label: "Net Revenue ($/lb)",
    resultHeaderIds: [30, 31, 32, 55, 56],
    resultColumns: [
      "net_revenue_lb_reference",
      "net_revenue_lb_scenario",
      "net_revenue_lb_delta",
      "net_revenue_lb_diff",
    ],
    compareHeaderIds: [14, 52, 53, 54],
    compareColumns: ["net_revenue_lb_reference", "net_revenue_lb_scenario1", "net_revenue_lb_scenario2"],
    manualHeaderIds: [23, 40],
    manualColumns: ["net_revenue_lb_reference"],
    checked: true,
  },
  {
    id: 12,
    label: "Dollar Share (%)",
    resultHeaderIds: [33, 34, 35, 57, 58],
    resultColumns: ["dollar_share_reference", "dollar_share_scenario", "dollar_share_delta", "dollar_share_diff"],
    compareHeaderIds: [12, 46, 47, 48],
    compareColumns: ["dollar_share_reference", "dollar_share_scenario1", "dollar_share_scenario2"],
    manualHeaderIds: [23, 40],
    manualColumns: ["dollar_share_reference"],
    checked: true,
  },
  {
    id: 13,
    label: "Gross Profit",
    resultHeaderIds: [12, 13, 14, 43, 44],
    resultColumns: ["gross_profit_reference", "gross_profit_scenario", "gross_profit_delta", "gross_profit_diff"],
    compareHeaderIds: [15, 55, 56, 57],
    compareColumns: ["gross_profit_reference", "gross_profit_scenario1", "gross_profit_scenario2"],
    manualHeaderIds: [26, 41],
    manualColumns: ["gross_profit_reference"],
    checked: true,
  },
  {
    id: 14,
    label: "Volume Sold (lbs)",
    resultHeaderIds: [21, 22, 23, 49, 50],
    resultColumns: [
      "volume_share_lb_reference",
      "volume_share_lb_scenario",
      "volume_share_lb_delta",
      "volume_share_lb_diff",
    ],
    compareHeaderIds: [7, 31, 32, 33],
    compareColumns: ["volume_share_lb_reference", "volume_share_lb_scenario1", "volume_share_lb_scenario2"],
    manualHeaderIds: [8, 37],
    manualColumns: ["volume_share_lb_reference"],
    checked: true,
  },
  {
    id: 15,
    label: "Units Sold",
    resultHeaderIds: [24, 25, 26, 51, 52],
    resultColumns: ["unit_sold_reference", "unit_sold_scenario", "unit_sold_delta", "unit_sold_diff"],
    compareHeaderIds: [8, 34, 35, 36],
    compareColumns: ["unit_sold_reference", "unit_sold_scenario1", "unit_sold_scenario2"],
    manualHeaderIds: [9, 38],
    manualColumns: ["unit_sold_reference"],
    checked: true,
  },
  {
    id: 16,
    label: "Volume Share (%)",
    resultHeaderIds: [27, 28, 29, 53, 54],
    resultColumns: [
      "volume_share_percent_reference",
      "volume_share_percent_scenario",
      "volume_share_percent_delta",
      "volume_share_percent_diff",
    ],
    compareHeaderIds: [9, 37, 38, 39],
    compareColumns: [
      "volume_share_percent_reference",
      "volume_share_percent_scenario1",
      "volume_share_percent_scenario2",
    ],
    manualHeaderIds: [17, 48],
    manualColumns: ["volume_share_percent_reference"],
    checked: true,
  },
];

export default columns;
