import { createAsyncThunk } from "@reduxjs/toolkit";
import { CMS_PATHS } from "../../../../constants/textConstants";
import { SupportState } from "../types/state";
import { apiGet } from "api";
import { CMS_BASE_URL } from "config/config";

const categoryIds: Record<keyof SupportState["faqs"], string> = {
  generalNrm: "19",
  generalPromoAi: "20",
  gettingStarted: "21",
  generate: "55",
  analyze: "56",
  adjust: "57",
  generalPricingAi: "58",
  admin: "59",
  inputs: "60",
  outputs: "61",
  models: "62",
};

export const getAllFaqs = createAsyncThunk("support/getAll", async (_, { rejectWithValue }) => {
  const apiCall = (categoryId: string) =>
    apiGet({
      url: `${CMS_BASE_URL}${CMS_PATHS.faq}`,
      params: {
        include: "category,tags",
        "views-filter[category]": categoryId,
      },
    });

  return Promise.all([
    apiCall(categoryIds.adjust),
    apiCall(categoryIds.admin),
    apiCall(categoryIds.analyze),
    apiCall(categoryIds.generalNrm),
    apiCall(categoryIds.generalPricingAi),
    apiCall(categoryIds.generalPromoAi),
    apiCall(categoryIds.generate),
    apiCall(categoryIds.gettingStarted),
    apiCall(categoryIds.inputs),
    apiCall(categoryIds.models),
    apiCall(categoryIds.outputs),
  ])
    .then(
      ([
        { data: adjust },
        { data: admin },
        { data: analyze },
        { data: generalNrm },
        { data: generalPricingAi },
        { data: generalPromoAi },
        { data: generate },
        { data: gettingStarted },
        { data: inputs },
        { data: models },
        { data: outputs },
      ]) => {
        return {
          adjust,
          admin,
          analyze,
          generalNrm,
          generalPricingAi,
          generalPromoAi,
          generate,
          gettingStarted,
          inputs,
          models,
          outputs,
        };
      },
    )
    .catch(() => {
      return rejectWithValue("error getting all faqs");
    });
});
