const themeCommonSettings = {
  spacing: (factor: number) => `${0.1 * factor}rem`,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#0195A1',
          padding: '9px 12px',
          minWidth: 0,
          fontSize: '1.4rem',
          textTransform: 'none',
          fontWeight: 400,
          letterSpacing: 0,
          width: 'fit-content',
          lineHeight: '100%',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: '#0195A1',
          },
        },
        startIcon: {
          marginLeft: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          border: '1px solid #C8C8C8',
          backgroundColor: '#fff',
        },
        notchedOutline: {
          color: 'green',
          borderWidth: '0px !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          padding: '8.5px 14px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#0195A1',
          textTransform: 'none',
          fontSize: '1.4rem',
          fontWeight: 400,
          lineHeight: '1.4rem',
          '&.Mui-selected': {
            color: '#000',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#0195A1',
          height: '0.3rem',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          paddingRight: '24px',
          boxSizing: 'border-box',
          borderRadius: '4px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginRight: '0px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #F2F2F2',
          '&:last-child': {
            borderBottom: 'none ',
          },
          '&.Mui-selected': {
            backgroundColor: '#fff',
          },
        },
        head: {
          '&:last-child': {
            borderBottom: '1px solid #F2F2F2',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: 400,
          borderBottom: 'none',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingLeft: '25px',
          paddingRight: '25px',
          paddingDenseLeft: '10px',
          paddingDenseRight: '10px',
          '& .MuiCheckbox-root': {
            opacity: 0.8,
          },
          '& .Mui-checked': {
            opacity: 1,
            color: '#0195A1',
          },
        },
        head: {
          fontWeight: 700,
          whiteSpace: 'nowrap',
        },
        paddingNone: {
          padding: '2px 0px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          opacity: 1,
          '&.Mui-checked': {
            opacity: 1,
            color: '#005CBC',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#D0D0D0',
        },
        '& .MuiRadio-root.Mui-disabled': {
          color: '#000000',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 26,
          height: 16,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
          },
        },
        switchBase: {
          padding: 0,
          '&.Mui-checked': {
            '& .MuiSwitch-thumb': {
              transform: 'translateX(-6px)',
            },
            '&+.MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#1D4659',
            },
          },
        },
        thumb: {
          backgroundColor: '#fff',
          border: '1px solid #1D4659',
        },
        track: {
          height: '16px',
          borderRadius: '12px',
          opacity: 1,
          backgroundColor: '#fff',
          border: '1px solid #1D4659',
        },
      },
    },
  },
};

export default themeCommonSettings;
