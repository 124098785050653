//Third-party dependencies.
import React, { ReactElement, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { NotificationContext } from "contexts/NotificationContext";
import InfoNotificationIcon from "assets/icons/notification/InfoNotificationIcon";
import SuccessNotificationIcon from "assets/icons/notification/SuccessNotificationIcon";
import WarningNotificationIcon from "assets/icons/notification/WarningNotificationIcon";
import ErrorNotificationIcon from "assets/icons/notification/ErrorNotificationIcon";
import ExitNotificationIcon from "assets/icons/notification/ExitNotificationIcon";
// import {  useTheme } from "@mui/material";

const Notification = (): ReactElement => {
  const { displayNotification, setDisplayNotification, notificationInfo } = useContext(NotificationContext);

  useEffect(() => {
    if (displayNotification) {
      setTimeout(() => {
        setDisplayNotification(false);
      }, 5000);
    }
  }, [displayNotification]);

  const closeNotification = () => {
    setDisplayNotification(false);
  };

  const backgroundColor = (severity: string) => {
    const colors = {
      success: "#008553",
      error: "#D40020",
      warning: "#FFBF1C",
      info: "#0078A7",
    };
    return colors[severity];
  };

  const textColor = (severity: string) => {
    const colors = {
      success: "#FFFFFF",
      error: "#ffffff",
      warning: "#3A3A3A",
      info: "#FFFFFF",
    };
    return colors[severity];
  };

  const displayIcon = (severity: string) => {
    const icons = {
      success: <SuccessNotificationIcon />,
      error: <ErrorNotificationIcon />,
      warning: <WarningNotificationIcon />,
      info: <InfoNotificationIcon />,
    };
    return icons[severity];
  };

  return (
    <>
      {displayNotification && (
        <div
          style={{
            width: "320px",
            minHeight: "40px",
            padding: "8px 16px 8px 16px",
            borderRadius: "4px",
            position: "absolute",
            top: "70px",
            right: "20px",
            backgroundColor: backgroundColor(notificationInfo.severity),
            zIndex: 100,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "10%" }}>{displayIcon(notificationInfo.severity)}</div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  color: textColor(notificationInfo.severity),
                  cursor: "default",
                }}
              >
                {notificationInfo.title}
              </p>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "23.8px",
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  color: textColor(notificationInfo.severity),
                }}
              >
                {notificationInfo.textMessage}
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", width: "40%", justifyContent: "flex-end" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {notificationInfo?.action && (
                  <div onClick={() => notificationInfo.action.onClick()}>
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        lineHeight: "23.8px",
                        marginBlockStart: 0,
                        marginBlockEnd: 0,
                        color: textColor(notificationInfo.severity),
                        cursor: "pointer",
                        marginRight: "4px",
                      }}
                    >
                      {notificationInfo.action.label}
                    </p>
                  </div>
                )}
                <ExitNotificationIcon
                  onClick={closeNotification}
                  white={notificationInfo.severity !== "warning" ? true : false}
                />
              </div>
            </div>
          </div>

          {/* <IconContainer>{icon}</IconContainer>
          <BreadCrumbContainer>{renderBreadcrumb}</BreadCrumbContainer> */}
        </div>
      )}
    </>
  );
};

export default Notification;
